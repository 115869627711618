import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import WunderflatsLogoBadge from "../WunderflatsLogo/WunderflatsLogoBadge";
import "./SidebarLayout.scss";

const propTypes = {
  children: PropTypes.node,
  sidebarChildren: PropTypes.node,
  className: PropTypes.string,
  onLogoClick: PropTypes.func,
};

const SidebarLayout = ({
  children,
  className,
  sidebarChildren,
  onLogoClick,
}) => {
  return (
    <div className={classNames("SidebarLayout", className)}>
      <a
        href="/"
        className="SidebarLayout-logo-outer-link"
        onClick={onLogoClick}
      >
        <WunderflatsLogoBadge />
      </a>
      <article className="SidebarLayout-MainContent">
        <div className="SidebarLayout-MainContentInner">{children}</div>
      </article>
      <aside className="SidebarLayout-Sidebar">{sidebarChildren}</aside>
    </div>
  );
};

SidebarLayout.propTypes = propTypes;

export default SidebarLayout;
