import React from "react";
import PropTypes from "prop-types";

const Key = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#24272E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M10.2 9a2.99 2.99 0 105.98 0 2.99 2.99 0 00-5.98 0z"
      />
      <path
        stroke="#24272E"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M10.2 9H1.36"
      />
      <path
        stroke="#24272E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M5.21 9.16v1.54M3 9.16v1.54"
      />
      <path
        stroke="#24272E"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M4.11 9.28v1.53"
      />
    </svg>
  );
};

Key.propTypes = {
  className: PropTypes.string,
};

export default Key;
