import {
  useMutation,
  useQueryClient,
  useQuery as useQueryReactQuery,
} from "@tanstack/react-query";
import { useApi } from "../spages/spa/context/ApiContext";

const TIME_30S = 30 * 1000;

// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
const keys = {
  all: () => ["EMAIL_SUBSCRIPTION_TYPES"],
  get: () => [...keys.all(), "GET"],
  update: () => [...keys.all(), "UPDATE"],
  zapierUpdate: () => [...keys.update(), "ZAPIER"],
};

export default {
  keys,
  useQuery: (options = {}) => {
    const api = useApi();

    return useQueryReactQuery(keys.get(), api.emailSubscriptionTypes.get, {
      staleTime: TIME_30S,
      ...options,
    });
  },

  useUpdate: (_options = {}) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const options = {
      ..._options,
      mutationKey: keys.update(),
      onMutate: async (newData) => {
        // optimistic update, needed for Switch to show correct data on error
        await queryClient.cancelQueries(keys.get());
        const previousData = queryClient.getQueryData(keys.get());
        queryClient.setQueryData(keys.get(), newData);

        const additionalContext = _options.onMutate?.(newData, previousData);

        return { previousData, ...additionalContext };
      },
      onSuccess: (data, ...rest) => {
        _options.onSuccess?.(data, ...rest);
      },
      onError: (err, newData, context) => {
        queryClient.setQueryData(keys.get(), context.previousData);
        _options.onError?.(err, newData, context);
      },
    };

    return useMutation(api.emailSubscriptionTypes.update, options);
  },

  useZapierUpdate: (_options = {}) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const options = {
      ..._options,
      mutationKey: keys.zapierUpdate(),
      onSuccess: (data, ...rest) => {
        queryClient.setQueryData(keys.get(), {
          emailSubscriptionTypes: rest[0],
        });
        _options.onSuccess?.(data, ...rest);
      },
    };

    return useMutation(
      api.emailSubscriptionTypes.updateEmailSubscriptionTypesViaZapier,
      options,
    );
  },
};
