import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ArrowRight from "../../ArrowRight/ArrowRight";
import EditIcon from "../../EditButton/EditIcon";
import TrashIcon from "../../Icons/Trash";

const propTypes = {
  btnLabel: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  error: PropTypes.string,
  icon: PropTypes.oneOf(["edit", "trash"]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onSubmit: PropTypes.func,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
};

const InputWithButton = forwardRef(
  (
    {
      value,
      defaultValue,
      type,
      name,
      error,
      onSubmit,
      onEdit,
      btnLabel,
      className,
      disabled,
      icon = "edit",
      editable = true,
      autoFocus = true,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef(null);

    useEffect(() => {
      if (!autoFocus) {
        return;
      }

      inputRef.current.focus();
    }, [editable, autoFocus]);

    // We can trigger "focus" event and get the "value"
    // of the input outside of the component via "useRef".
    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current.focus();
      },
      value: () => {
        return inputRef.current.value;
      },
    }));

    const onKeyDown = ({ key }) => {
      if (key !== "Enter") {
        return;
      }

      onSubmit();
    };

    return (
      <Fragment>
        <div
          className={classnames(
            "InputWithButton",
            {
              "InputWithButton--disabled": !editable,
            },
            className,
          )}
        >
          <div
            className={classnames("InputWithButton-inputContainer", {
              "InputWithButton-inputContainer--disabled": !editable,
            })}
          >
            <input
              ref={inputRef}
              name={name}
              type={type}
              value={value}
              defaultValue={defaultValue}
              className={classnames("InputWithButton-input", {
                "InputWithButton-input--error": !!error,
                "InputWithButton-input--disabled": !editable,
              })}
              onKeyDown={onKeyDown}
              disabled={disabled || !editable}
              {...props}
            />
            <label
              className={classnames("InputWithButton-label", {
                "InputWithButton-label--visible": !editable,
              })}
            >
              {value}
            </label>
          </div>
          {editable ? (
            <button
              type="button"
              className={classnames(
                "InputWithButton-button",
                "InputWithButton-button--submit",
                "InputWithButton-button--valid",
              )}
              onClick={onSubmit}
            >
              <ArrowRight
                width={16}
                height={14}
                pathClassName="InputWithButton-arrowRight"
              />
              <div className="InputWithButton-btnLabel">{btnLabel}</div>
            </button>
          ) : (
            <button
              type="button"
              className={classnames(
                "InputWithButton-button",
                "InputWithButton-button--edit",
              )}
              onClick={onEdit}
            >
              {icon === "edit" ? (
                <EditIcon width={16} height={16} />
              ) : (
                <TrashIcon width={16} height={16} />
              )}
            </button>
          )}
        </div>
        <div className="InputWithButton-errorMsg">{error}</div>
      </Fragment>
    );
  },
);

InputWithButton.propTypes = propTypes;

export default InputWithButton;
