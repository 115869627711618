import getWFCONFIG from "../../utils/WF_CONFIG";

const WF_CONFIG = getWFCONFIG();

const RE_CAPTCHA_SITE_KEY = WF_CONFIG.RE_CAPTCHA_SITE_KEY;

const ENV = WF_CONFIG.NODE_ENV;
const IS_RE_CAPTCHA_ENABLED = ENV === "production" || ENV === "staging";

export default ({ action }) => {
  if (!IS_RE_CAPTCHA_ENABLED) {
    return Promise.resolve("disabled");
  }

  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(() => {
      return window.grecaptcha
        .execute(RE_CAPTCHA_SITE_KEY, { action })
        .then((token) => resolve(token))
        .catch((error) => {
          window.rollbar?.error("Failed to generate ReCaptcha token", error);
          reject(error);
        });
    });
  });
};
