import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { getCountryDialCode } from "../../../utils/listing-utils";
import { countryDialCodes, getFlagIcon } from "../../../utils/phoneNumbers";

const hashDialCodeCountryCode = countryDialCodes.reduce(
  /* eslint-disable camelcase */
  (acc, { dial_code, code }) => {
    acc[dial_code] = code;
    return acc;
  },
  {},
  /* eslint-enable camelcase */
);

const propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  error: PropTypes.bool,
  countryCode: PropTypes.string,
};

const serialize = (dialCode, phoneNumber) => {
  return `+${dialCode} ${phoneNumber}`;
};

// The API always returns phone number in a format dialCode[SPACE]phoneNUmber
const deserialize = (value) => {
  const [dialCode, ...rest] = value.split(" ");
  return {
    dialCode: dialCode.replace("+", ""),
    phoneNumber: rest.join(""),
  };
};

const PhoneNumberInput = React.forwardRef(
  (
    {
      className,
      name,
      error,
      t,
      onChange,
      onFocus,
      onBlur,
      value,
      countryCode: country,
    },
    ref,
  ) => {
    const sortedDialCodes = countryDialCodes.sort((countryA, countryB) => {
      const a = t(`countries.${countryA.code}`);
      const b = t(`countries.${countryB.code}`);
      return a.localeCompare(b);
    });

    const dialCode = deserialize(value).dialCode || getCountryDialCode(country);

    const phoneNumber = deserialize(value).phoneNumber || "";

    const countryCode = hashDialCodeCountryCode[dialCode];

    const flag = getFlagIcon(countryCode);

    const onChangePhoneNumber = (phoneNumber) => {
      if (!/^\d*$/.test(phoneNumber)) {
        return;
      }

      onChange(serialize(dialCode, phoneNumber));
    };

    const onChangeDialCode = (dialCode) => {
      onChange(serialize(dialCode, phoneNumber));
    };

    return (
      <div
        className={classnames(className, "PhoneNumberInput", {
          "PhoneNumberInput--error": error,
        })}
        onFocus={() => {
          if (onFocus) {
            onFocus();
          }
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
        }}
      >
        <select
          data-testid="PhoneNumberInput-select"
          className="PhoneNumberInput-countrySelect"
          onChange={(e) => onChangeDialCode(e.target.value)}
          value={dialCode}
        >
          <option hidden value="" />
          {sortedDialCodes.map((value) => {
            return (
              <option key={value.code} value={value.dial_code}>
                {t(`countries.${value.code}`)}
              </option>
            );
          })}
        </select>
        <div className="PhoneNumberInput-flag">
          <figure className="PhoneNumberInput-flag--circular">
            <img
              className="PhoneNumberInput-flag-image"
              alt={countryCode}
              src={flag}
            />
          </figure>
        </div>
        <span
          className="PhoneNumberInput-dialCode"
          data-testid="PhoneNumberInput-dialCode"
        >{`+${dialCode}`}</span>
        <input
          ref={ref}
          data-testid="PhoneNumberInput-textInput"
          className={classnames("PhoneNumberInput-phoneInput", {
            "PhoneNumberInput-phoneInput--error": error,
          })}
          type="tel"
          onChange={(e) => onChangePhoneNumber(e.target.value)}
          value={phoneNumber}
          placeholder="00000000000"
          maxLength="15"
        />
        <input
          data-testid="PhoneNumberInput-hiddenInput"
          type="hidden"
          value={value}
          name={name}
        />
      </div>
    );
  },
);

PhoneNumberInput.propTypes = propTypes;
export default PhoneNumberInput;
