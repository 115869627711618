import React from "react";

const Lock = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9834 14.3996C23.9834 13.4406 23.2369 12.6717 22.3244 12.6717H20.7069V6.95248C20.7069 3.66955 18.1438 1 14.9917 1C11.8479 1 9.29309 3.66955 9.29309 6.95248V12.6976H7.65899C6.73825 12.7063 6 13.4752 6 14.4341L6.01659 27.2808C6.01659 28.2311 6.76313 29.0086 7.67558 29L22.341 28.9741C23.2618 28.9741 24 28.1965 24 27.2462L23.9834 14.3996ZM10.5373 6.95248C10.5373 4.38661 12.5364 2.2959 14.9917 2.2959C17.4553 2.2959 19.4627 4.38661 19.4627 6.95248V12.6803L10.5373 12.689V6.95248ZM16.5346 24.5335H13.4654L14.2452 20.5594C13.8553 20.3607 13.5401 20.0151 13.3908 19.5832C13.3078 19.3844 13.2664 19.1685 13.2664 18.9438C13.2664 18.7106 13.3078 18.4946 13.3908 18.2873C13.6396 17.6134 14.27 17.1382 15 17.1382C15.73 17.1382 16.3521 17.6134 16.6092 18.2873C16.6839 18.4946 16.7253 18.7106 16.7253 18.9438C16.7253 19.1685 16.6839 19.3844 16.6009 19.5832C16.4516 20.0151 16.1447 20.3607 15.7548 20.5594L16.5346 24.5335Z"
        fill="white"
      />
    </svg>
  );
};

export default Lock;
