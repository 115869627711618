import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

const EditIcon = ({
  className,
  fillColor = "#24272E",
  height = 14,
  width = 14,
}) => {
  return (
    <svg
      className={className}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 17"
    >
      <path
        className="EditIcon-pencil"
        d="M2.5 10.65l-.63.64-.7 3.18a.31.31 0 00.38.38l3.12-.76.62-.61a5.16 5.16 0 01-1.78-1.15 3.35 3.35 0 01-1.01-1.68zm7.23-7.22L3.23 10a3.27 3.27 0 00.94 1.69A4.49 4.49 0 006 12.76l6.5-6.53a4.74 4.74 0 01-1.72-1.12 4.26 4.26 0 01-1.05-1.68zm5.1-.43a2 2 0 00-2-1.86h-.13a2 2 0 00-1.26.58l-1 1a3.15 3.15 0 001.1 1.74 4.2 4.2 0 001.71 1.06l.78-.79.4-.41a1.92 1.92 0 00.39-1.18.57.57 0 00.01-.14z"
        fill={fillColor}
      />
    </svg>
  );
};

EditIcon.propTypes = propTypes;

export default EditIcon;
