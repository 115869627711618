import { useMutation } from "@tanstack/react-query";
import { useApi } from "../spages/spa/context/ApiContext";

// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
const keys = {
  all: () => ["AUTH_LOGIN_CODE"],
  requestLoginCode: () => [...keys.all(), "REQUEST"],
};

export default {
  keys,
  useRequestLoginCode: (_options = {}) => {
    const api = useApi();

    const options = {
      ..._options,
      mutationKey: keys.requestLoginCode(),
    };

    return useMutation(api.auth.requestLoginCode, options);
  },
};
