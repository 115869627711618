import React, { useCallback, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import store from "store2";
import apiStore from "../../../apiStore";
import { AuthContext } from "../../../contexts/AuthContext";
import { useI18n } from "../../../spages/spa/context/I18nContext";
import {
  composeValidators,
  minLength,
  required,
} from "../../../utils/final-form-validators";
import ArrowRight from "../../ArrowRight/ArrowRight";
import CodeInput from "../../CodeInput/CodeInput";
import { Button } from "../../DesignSystem/Button/Button";
import FinalFormErrorIndicator from "../../ErrorIndicator/FinalFormErrorIndicator";
import Sync from "../../Icons/Sync";
import toaster from "../../Toaster/Toaster";
import AuthComponentButton from "../AuthComponentButton/AuthComponentButton";
import { STEP_EMAIL_VERIFICATION, STEP_PASSWORD } from "../constants";
import "./LoginCode.scss";

const NOT_PERMITTED_ERROR = "NotPermittedError";
const AUTHENTICATION_REQUIRED_ERROR = "AuthenticationRequiredError";

const LoginCode = ({ analyticsEvent, email, changeStep, onLogin }) => {
  const { t } = useI18n();
  const inputRef = useRef();
  const formRef = useRef();
  const { loginWithCode } = useContext(AuthContext);

  const showErrorToaster = useCallback(() => {
    toaster.basicToaster.add({
      timeout: 5000,
      title: t("errors.somethingWentWrong"),
      text: t("errors.pleaseTryAgain"),
      intent: toaster.intents.danger,
    });
  }, [t]);

  const showResendLoginCodeSuccessToaster = useCallback(() => {
    toaster.basicToaster.add({
      timeout: 5000,
      text: t("components.AuthComponent.ForgotPassword.toastSuccess"),
      title: t("emailSent"),
      intent: toaster.intents.brand,
    });
  }, [t]);

  const {
    mutate: mutateRequestLoginCode,
    mutateAsync: mutateAsyncRequestLoginCode,
  } = apiStore.loginCodeStore.useRequestLoginCode({
    onError: () => {
      showErrorToaster();
      changeStep(STEP_PASSWORD);
    },
  });

  const resendLoginCode = useCallback(async () => {
    await mutateAsyncRequestLoginCode({ email });
    showResendLoginCodeSuccessToaster();
  }, [email, mutateAsyncRequestLoginCode, showResendLoginCodeSuccessToaster]);

  useEffect(() => {
    inputRef.current.focus();
    mutateRequestLoginCode({ email });

    analyticsEvent("Login Code step view");
  }, [analyticsEvent, email, mutateRequestLoginCode]);

  const handleSubmit = async ({ loginCode }) => {
    try {
      const shortlistGroups = store.get("default") || [];
      const user = await loginWithCode({
        email,
        loginCode,
        shortlist: {
          name: "default",
          items: shortlistGroups,
        },
      });
      analyticsEvent("Login With Code completed");
      if (onLogin) {
        onLogin(user);
      }
    } catch (error) {
      analyticsEvent("Login With Code submit error");
      if (
        [NOT_PERMITTED_ERROR, AUTHENTICATION_REQUIRED_ERROR].includes(error)
      ) {
        changeStep(STEP_EMAIL_VERIFICATION);
      } else if (error === "Unexpected") {
        showErrorToaster();
        changeStep(STEP_PASSWORD);
      } else {
        return { loginCode: "invalidOrExpired" };
      }
    }
  };

  return (
    <div className="LoginCode" data-testid="loginCode">
      <Form
        subscription={{ submitFailed: true, error: true, submitting: true }}
        onSubmit={handleSubmit}
        render={({ handleSubmit, form, submitting }) => {
          formRef.current = form;
          return (
            <form
              className="LoginCode-form"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Field
                ref={inputRef}
                name="loginCode"
                validate={composeValidators(required, minLength(6))}
                render={({ input, meta }) => {
                  return (
                    <FinalFormErrorIndicator
                      meta={meta}
                      input={input}
                      errorsMap={
                        new Map([
                          [
                            "invalidOrExpired",
                            t("components.LoginCode.invalidOrExpired"),
                          ],
                        ])
                      }
                    >
                      <CodeInput {...input} ref={inputRef} />
                    </FinalFormErrorIndicator>
                  );
                }}
              />
              <div className="LoginCode-submit-container">
                <Button
                  loading={submitting}
                  type="submit"
                  className="LoginCode-submitButton"
                  data-testid="submitLoginCodeButton"
                >
                  <span className="LoginCode-submit-text">
                    {t("components.LoginCode.submit")}
                  </span>
                  <ArrowRight
                    width={16}
                    height={14}
                    className="LoginCode-submit-icon"
                  />
                </Button>
              </div>
            </form>
          );
        }}
      />
      <AuthComponentButton
        dataTestId="resendLoginCodeButton"
        onClick={() => {
          resendLoginCode();
          formRef.current.reset();
        }}
      >
        <AuthComponentButton.Icon icon={Sync} width={18} height={18} />
        <AuthComponentButton.Label>
          {t("components.LoginCode.resend")}
        </AuthComponentButton.Label>
      </AuthComponentButton>
    </div>
  );
};

LoginCode.propTypes = {
  analyticsEvent: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  changeStep: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
};

export default LoginCode;
