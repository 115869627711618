import _flattenDeep from "lodash/flattenDeep";

// eslint-disable-next-line import/prefer-default-export
export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    );

export const getFinalFormErrorsArray = (errors) => {
  if (!errors) {
    return [];
  }
  const errorsArray = Object.values(errors).map((error) => {
    if (typeof error === "string") {
      return error;
    }

    return getFinalFormErrorsArray(error);
  });

  return _flattenDeep(errorsArray);
};

export const required = (value) =>
  value === undefined || value === null || value.trim?.() === ""
    ? "required"
    : undefined;

export const maxLength = (expectation) => (value) =>
  value?.length > expectation
    ? { name: "maxLengthExceeded", expectation }
    : undefined;

export const minLength = (expectation) => (value) => {
  return value?.length < expectation
    ? { name: "mustBeMin", expectation }
    : undefined;
};
