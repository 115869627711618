import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import serialize from "form-serialize";
import { useFeatureFlags } from "../../contexts/FeatureFlagContext";
import { useAnalytics } from "../../spages/spa/context/AnalyticsContext";
import validatePassword, {
  validatePasswordIsSecure,
} from "../../utils/validate-password";
import getWFCONFIG from "../../utils/WF_CONFIG";
import getReCaptchaToken from "../AuthComponent/getReCaptchaToken";
import CardModal from "../CardModal/CardModal";
import { Button } from "../DesignSystem/Button/Button";
import Lock from "../Icons/Lock";
import PasswordLock from "../Icons/PasswordLock";
import ResetPasswordForm from "../ResetPasswordForm/ResetPasswordForm";
import toaster from "../Toaster/Toaster";
import "./ResetPasswordModal.scss";

const WF_CONFIG = getWFCONFIG();

const ENV = WF_CONFIG.NODE_ENV;
const IS_RE_CAPTCHA_ENABLED = ENV === "production" || ENV === "staging";

// TODO DEV-14774 Since this will be just a call to validatePasswordIsSecure, it can be
// called directly in onSubmit
const validateSubmittedPassword = ({
  password,
  isWeakPasswordFeatureFlagEnabled,
  t,
}) => {
  if (isWeakPasswordFeatureFlagEnabled) {
    return validatePasswordIsSecure({ password, t });
  }
  return validatePassword({ password, t });
};

const propTypes = {
  t: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
};

const ResetPasswordModal = ({ t, onSuccess, resetPassword }) => {
  const analytics = useAnalytics();
  const formRef = useRef(null);
  // TODO DEV-14774 Remove feature flag usage
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const isWeakPasswordFeatureFlagEnabled = isFeatureFlagEnabled(
    "ENVIRONMENT_WEAK_PASSWORDS",
  );

  const [password, setPassword] = useState({
    value: null,
    show: false,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [passwordSuccessfullyReset, setPasswordSuccessfullyReset] =
    useState(false);
  const [email, setEmail] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    const values = serialize(formRef.current, { hash: true });

    const passwordError = validateSubmittedPassword({
      t,
      password: values.password,
      isWeakPasswordFeatureFlagEnabled,
    });

    if (passwordError && !isWeakPasswordFeatureFlagEnabled) {
      setError(passwordError);
    }
    setLoading(true);

    try {
      const reCaptchaToken = IS_RE_CAPTCHA_ENABLED
        ? await getReCaptchaToken({
            action: "resetPassword",
          })
        : "disabled";

      const { email } = await resetPassword({
        reCaptchaToken,
        password: values?.password,
      });

      analytics.event("LoginSignup", "Reset password success");

      if (isWeakPasswordFeatureFlagEnabled) {
        setPasswordSuccessfullyReset(true);
        setEmail(email);
      } else {
        toaster.basicToaster.add({
          timeout: 5000,
          title: t("passwordHasBeenResetSuccessfully"),
          text: t("pleaseLoginWithYourNewPassword"),
          intent: toaster.intents.success,
        });
        setIsModalOpen(false);
        onSuccess(email);
      }
    } catch (error) {
      if (isWeakPasswordFeatureFlagEnabled) {
        setError(passwordError || t("errors.somethingWentWrong"));
      } else {
        toaster.basicToaster.add({
          timeout: 5000,
          title: t("errors.somethingWentWrong"),
          text: t("errors.pleaseTryAgain"),
          intent: toaster.intents.danger,
        });
      }

      analytics.event("LoginSignup", "Reset password error");
    }

    setLoading(false);
  };

  const showNewResetPasswordModal =
    isWeakPasswordFeatureFlagEnabled && !passwordSuccessfullyReset;
  const showSuccessScreen =
    isWeakPasswordFeatureFlagEnabled && passwordSuccessfullyReset;

  let modalTitle;
  let modalSubtitle;
  if (showNewResetPasswordModal) {
    modalTitle = t("components.ResetPasswordModal.title");
    modalSubtitle = t("components.ResetPasswordModal.subtitle");
  } else if (showSuccessScreen) {
    modalTitle = t("components.ResetPasswordModal.success.title");
    modalSubtitle = t("pleaseLoginWithYourNewPassword");
  } else {
    modalTitle = t("components.ForgotPasswordModal.title");
    modalSubtitle = t("pleaseEnterASecurePassword");
  }

  const inputLabel = t("newPassword");
  const submitButtonLabel = t("components.ResetPasswordForm.action");

  const onHideModal = () => {
    setIsModalOpen(false);
    // TODO DEV-14774 replace showSuccessScreen with passwordSuccessfullyReset
    if (showSuccessScreen) {
      onSuccess(email);
    }
  };

  return (
    <CardModal
      className="ResetPasswordModal"
      visible={isModalOpen}
      onHide={onHideModal}
      showCloseButton={!isWeakPasswordFeatureFlagEnabled || showSuccessScreen}
    >
      <CardModal.Header
        icon={
          isWeakPasswordFeatureFlagEnabled ? (
            <PasswordLock />
          ) : (
            // TODO DEV-14774 Lock icon doesn't seem to be used anywhere else, we can probably remove it
            <Lock />
          )
        }
        title={modalTitle}
        subtitle={modalSubtitle}
        sentiment={isWeakPasswordFeatureFlagEnabled ? "default" : "gradient"}
      />
      <CardModal.Body>
        {!isWeakPasswordFeatureFlagEnabled && (
          <ResetPasswordForm
            t={t}
            label={inputLabel}
            value={password.password}
            showPassword={password.show}
            toggleShowPassword={() =>
              setPassword({ ...password, show: !password.show })
            }
            ref={formRef}
            onSubmit={onSubmit}
            errorMessage={error}
          />
        )}
        {showNewResetPasswordModal && (
          <ResetPasswordForm
            t={t}
            label={inputLabel}
            value={password.password}
            setValue={(event) => {
              const newInputValue = event?.target?.value;
              setPassword((previousPassword) => {
                // When the user changes the value, remove the previous error message
                if (error && previousPassword.password !== newInputValue) {
                  setError(null);
                }
                return { ...previousPassword, password: newInputValue };
              });
            }}
            showPassword={password.show}
            toggleShowPassword={() =>
              setPassword({ ...password, show: !password.show })
            }
            ref={formRef}
            onSubmit={onSubmit}
            errorMessage={error}
          />
        )}
      </CardModal.Body>
      {!showSuccessScreen && (
        <CardModal.Footer>
          <CardModal.ButtonGroup>
            <Button
              dataTestId="ResetPasswordModal-submitPasswordButton"
              widthVariant="fullWidth"
              loading={loading}
              onClick={onSubmit}
            >
              {`${submitButtonLabel}`}
            </Button>
          </CardModal.ButtonGroup>
        </CardModal.Footer>
      )}
    </CardModal>
  );
};

ResetPasswordModal.propTypes = propTypes;
export default ResetPasswordModal;
