import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "../DesignSystem/IconButton/IconButton";
import SyncIcon from "../Icons/Sync";
import { STEP_EMAIL } from "./constants";

const EmailVerification = ({ changeStep, setEmail, email, t }) => {
  const changeEmail = () => {
    changeStep(STEP_EMAIL);
    setEmail("");
  };

  return (
    <div className="AuthComponent AuthComponent-EmailVerification">
      <div>
        <h1 className="AuthComponent-EmailVerification-title">
          {t("components.AuthComponent.EmailVerification.title")}
        </h1>

        <p className="AuthComponent-EmailVerification-description">
          {t("components.AuthComponent.EmailVerification.description.part1")}
          <span className="AuthComponent-EmailVerification-email">{email}</span>
          {t("components.AuthComponent.EmailVerification.description.part2")}
        </p>
      </div>

      <IconButton
        icon={<SyncIcon />}
        iconBackgroundColor="none"
        className="AuthComponent-EmailVerification-button"
        size="large"
        label={t(
          "components.AuthComponent.EmailVerification.changeEmailButton",
        )}
        name={t("components.AuthComponent.EmailVerification.changeEmailButton")}
        onClick={changeEmail}
      />
    </div>
  );
};

EmailVerification.propTypes = {
  changeStep: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default EmailVerification;
