import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  variant: PropTypes.oneOf(["gray", "gradient"]),
  height: PropTypes.number,
  width: PropTypes.number,
};

const CrossIcon = ({ variant, width = 18, height = 18, ...rest }) => {
  let fill = "";

  if (variant === "gray") {
    fill = "#7B8389";
  }

  if (variant === "gradient") {
    fill = "url(#gradient)";
  }

  return (
    <svg viewBox="0 0 18 18" width={width} height={height} {...rest}>
      <defs>
        <linearGradient id="gradient" x1="0" y1="0" x2="100%" y2="100%">
          <stop offset="25%" stopColor="#8457DF" />
          <stop offset="90%" stopColor="#A03FC5" />
        </linearGradient>
      </defs>
      <polygon
        fill={fill}
        points="13.24 3.3 9 7.54 4.76 3.3 3.34 4.71 7.59 8.96 3.34 13.2 4.76 14.61 9 10.37 13.24 14.61 14.66 13.2 10.41 8.96 14.66 4.71 13.24 3.3"
      />
    </svg>
  );
};

CrossIcon.propTypes = propTypes;
export default CrossIcon;
