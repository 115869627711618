import React from "react";
import PropTypes from "prop-types";
import { useTracker } from "../../spages/spa/context/TrackerContext";
import AppleIcon from "../Icons/Apple";
import FacebookIcon from "../Icons/Facebook";
import GoogleIcon from "../Icons/Google";
import LinkedInIcon from "../Icons/LinkedIn";

const SOCIALS = [
  {
    name: "google",
    icon: <GoogleIcon />,
  },
  {
    name: "facebook",
    icon: <FacebookIcon />,
  },
  {
    name: "linkedin",
    icon: <LinkedInIcon />,
  },
  {
    name: "apple",
    icon: <AppleIcon />,
  },
];

const SocialButtons = ({ eventLabel, redirect, t }) => {
  const { tracker } = useTracker();

  return (
    <div className="SocialButtons">
      <h3 className="SocialButtons-title">
        {t("components.AuthComponent.SocialLogin.continueWith")}
      </h3>

      <div className="SocialButtons-buttonsContainer">
        {SOCIALS.map(({ name, icon }) => (
          <a
            key={name}
            href={`/api/auth/${name}?redirect=${redirect}&eventLabel=${eventLabel}`}
            className="SocialButtons-Button"
            onClick={() => {
              tracker.events.trackSignupClicked({ loginMethod: name });
            }}
          >
            <div className="SocialButtons-Button-icon">{icon}</div>

            <span className="SocialButtons-Button-label">{name}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

SocialButtons.propTypes = {
  eventLabel: PropTypes.string.isRequired,
  redirect: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default SocialButtons;
