import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./InformationTable.scss";

export const informationTablePropTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.node.isRequired,
      value: PropTypes.shape({
        text: PropTypes.node.isRequired,
        isGrayed: PropTypes.bool,
        isCrossed: PropTypes.bool,
      }).isRequired,
    }),
  ).isRequired,
  heading: PropTypes.string,
};

const InformationTable = ({ rows, heading }) => (
  <table className="InformationTable">
    {heading && (
      <thead>
        <tr>
          <th className="InformationTable-heading">{heading}</th>
        </tr>
      </thead>
    )}
    <tbody>
      {rows?.map(({ key, value }) => {
        const { text, isGrayed, isCrossed } = value;

        return (
          text && (
            <tr
              data-testid={`InformationTable-row-${key}`}
              key={key}
              className="InformationTable-row"
            >
              <td
                data-testid="InformationTable-row-key"
                className="InformationTable-key"
              >
                {key}
              </td>
              <td
                data-testid="InformationTable-row-value"
                className={classnames("InformationTable-text", {
                  "InformationTable-text--isGrayed": isGrayed,
                  "InformationTable-text--isCrossed": isCrossed,
                })}
              >
                {text}
              </td>
            </tr>
          )
        );
      })}
    </tbody>
  </table>
);

InformationTable.propTypes = informationTablePropTypes;
export default InformationTable;
