import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useI18n } from "../../spages/spa/context/I18nContext";
import { useRouterUtils } from "../../spages/spa/context/RouterUtilsContext";
import CoverImage from "../CoverImage/CoverImage";
import ListingCostSummary from "../ListingCostSummary/ListingCostSummary";

import "./ListingSummary.scss";

export const listingSummaryPropTypes = {
  coverImageProps: PropTypes.shape({
    src: PropTypes.string.isRequired,
    sizes: PropTypes.string,
    srcSet: PropTypes.string,
    alt: PropTypes.string,
  }),
  title: PropTypes.shape({
    en: PropTypes.string.isRequired,
    de: PropTypes.string.isRequired,
  }).isRequired,
  address: PropTypes.shape({
    city: PropTypes.string.isRequired,
    districts: PropTypes.array,
    zipCode: PropTypes.string.isRequired,
  }).isRequired,
  rooms: PropTypes.number.isRequired,
  beds: PropTypes.number.isRequired,
  area: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  accommodates: PropTypes.number.isRequired,
  deposit: PropTypes.number,
  finalCleaningFee: PropTypes.number,
  lang: PropTypes.string.isRequired,
  restrictions: PropTypes.object,
  groupId: PropTypes.string.isRequired,
  openListingDetailPageOnClinkInTheCoverImage: PropTypes.bool,
  listingId: PropTypes.string.isRequired,
  partOfGroup: PropTypes.bool.isRequired,
  sftValue: PropTypes.number,
  tenantServiceFeeViewedCallback: PropTypes.func,
};

const ListingSummary = ({
  groupId,
  coverImageProps,
  title,
  address,
  rooms,
  beds,
  area,
  price,
  currency,
  accommodates,
  deposit,
  finalCleaningFee,
  lang,
  restrictions,
  openListingDetailPageOnClinkInTheCoverImage = false,
  listingId,
  partOfGroup,
  sftValue,
  tenantServiceFeeViewedCallback,
}) => {
  const { t, extractContent } = useI18n();
  let location = "";
  const { url } = useRouterUtils();

  if (!address.districts || address.districts.length === 0) {
    location = `${address.city}`;
  } else if (address.districts.length > 0) {
    location = `${address.districts[0]}, ${address.city}`;
  }

  let listingDetailsUrl = "";
  const titleTranslationText = extractContent(title);

  if (partOfGroup) {
    listingDetailsUrl = url("multilistingdetails", {
      title: titleTranslationText,
      groupId,
    });
  } else {
    listingDetailsUrl = url("listingdetails", {
      id: listingId,
      title: titleTranslationText,
      lang,
    });
  }

  const imageContainer = openListingDetailPageOnClinkInTheCoverImage ? (
    <Link to={listingDetailsUrl} target="_blank">
      <div
        data-testid="ListingSummary-imgContainer"
        className="ListingSummary-imgContainer"
      >
        <CoverImage
          loading="eager"
          className="ListingSummary-imgContainer-img"
          {...coverImageProps}
        />
      </div>
    </Link>
  ) : (
    <div
      data-testid="ListingSummary-imgContainer"
      className="ListingSummary-imgContainer"
    >
      <CoverImage
        loading="eager"
        className="ListingSummary-imgContainer-img"
        {...coverImageProps}
      />
    </div>
  );

  return (
    <div className="ListingSummary" data-testid={groupId}>
      {imageContainer}
      {titleTranslationText && (
        <h2 data-testid="ListingSummary-title" className="ListingSummary-title">
          {titleTranslationText}
        </h2>
      )}
      {location && (
        <h3
          data-testid="ListingSummary-location"
          className="ListingSummary-location"
        >
          {location}
        </h3>
      )}
      <p className="ListingSummary-details">
        {rooms && (
          <span
            data-testid="ListingSummary-details-numRooms"
            className="ListingSummary-details-item"
          >
            {t("numRooms", { numRooms: rooms })}
          </span>
        )}
        {beds && (
          <span
            data-testid="ListingSummary-details-numBeds"
            className="ListingSummary-details-item"
          >
            {t("numBeds", { numBeds: beds })}
          </span>
        )}
        {accommodates && (
          <span
            data-testid="ListingSummary-details-numGuests"
            className="ListingSummary-details-item"
          >
            {t("spages.listing.ListingDetailsPageBase.stats.guests", {
              guests: accommodates,
            })}
          </span>
        )}
        {area && (
          <span
            data-testid="ListingSummary-details-area"
            className="ListingSummary-details-item"
          >{`${area} m²`}</span>
        )}
      </p>
      <ListingCostSummary
        currency={currency}
        price={price}
        deposit={deposit}
        finalCleaningFee={finalCleaningFee}
        t={t}
        lang={lang}
        restrictions={restrictions}
        sftValue={sftValue}
        tenantServiceFeeViewedCallback={tenantServiceFeeViewedCallback}
      />
    </div>
  );
};

ListingSummary.propTypes = listingSummaryPropTypes;

export default ListingSummary;
