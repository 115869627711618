import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useFeatureFlags } from "../../contexts/FeatureFlagContext";
import { Button } from "../DesignSystem/Button/Button";
import { IconButton } from "../DesignSystem/IconButton/IconButton";
import CloseIcon from "../Icons/Close";
import Padlock from "../Icons/Padlock";
import Popover, { placements } from "../Popover/Popover";
import toaster from "../Toaster/Toaster";

const propTypes = {
  analyticsEvent: PropTypes.func.isRequired,
  className: PropTypes.string,
  email: PropTypes.string.isRequired,
  sendPasswordResetLink: PropTypes.func.isRequired,
  showInDrawer: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

const ForgotPassword = ({
  analyticsEvent,
  className,
  email,
  sendPasswordResetLink,
  showInDrawer,
  t,
}) => {
  const [loading, setLoading] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const { isFeatureFlagEnabled } = useFeatureFlags();
  const isWeakPasswordFeatureFlagEnabled = isFeatureFlagEnabled(
    "ENVIRONMENT_WEAK_PASSWORDS",
  );

  useEffect(() => {
    document.body.classList.add("ForgotPasswordView");

    return () => {
      document.body.classList.remove("ForgotPasswordView");
    };
  }, []);

  const ForgotPasswordButton = (
    <button
      className={classnames("ForgotPassword", className)}
      onClick={() => setShowPopover(true)}
      data-testid="ForgotPasswordButton"
    >
      <Padlock className="ForgotPassword-padlock" />
      <span className="ForgotPassword-label">
        {t("components.AuthComponent.ForgotPassword.label")}
      </span>
    </button>
  );

  const handlePasswordReset = async () => {
    setLoading(true);

    try {
      await sendPasswordResetLink({
        email,
      });

      toaster.basicToaster.add({
        timeout: 5000,
        text: t("components.AuthComponent.ForgotPassword.toastSuccess"),
        title: t("emailSent"),
        intent: toaster.intents.brand,
      });

      analyticsEvent("Password step forgot");
    } catch (error) {
      toaster.basicToaster.add({
        timeout: 5000,
        title: t("components.ErrorMessage.ServerError"),
        intent: toaster.intents.danger,
      });
    }

    setLoading(false);
    setShowPopover(false);
  };

  return (
    <Popover
      around={ForgotPasswordButton}
      show={showPopover}
      placement={placements.right}
      onClose={() => setShowPopover(false)}
      showInDrawer={showInDrawer}
    >
      {
        /** TODO: remove this button */ !isWeakPasswordFeatureFlagEnabled &&
          !showInDrawer && (
            <IconButton
              onClick={() => setShowPopover(false)}
              icon={<CloseIcon />}
              className="ForgotPassword-popover-closeBtn"
              name="ForgotPassword-closeBtn"
            />
          )
      }

      {isWeakPasswordFeatureFlagEnabled ? (
        <React.Fragment>
          <h2
            data-testid="ForgotPasswordPopover-title"
            className="ForgotPassword-popover-title-less-margin"
          >
            {t("components.AuthComponent.ForgotPassword.popover.title")}
          </h2>
          <p className="ForgotPassword-popover-text-less-wide">
            {t("components.AuthComponent.ForgotPassword.popover.text")}
            <br />
            <span className="ForgotPassword-popover-email">{email}</span>
          </p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h2 className="ForgotPassword-popover-title">
            {t("components.AuthComponent.ForgotPassword.popoverTitle")}
          </h2>
          <p className="ForgotPassword-popover-text">
            {t("components.AuthComponent.ForgotPassword.popoverText", {
              email,
            })}
          </p>
        </React.Fragment>
      )}

      <div className="ForgotPassword-popover-ctaButtons">
        {isWeakPasswordFeatureFlagEnabled ? (
          <Button
            loading={loading}
            dataTestId="handlePasswordResetButton"
            onClick={handlePasswordReset}
            name="ForgotPassword-send"
            widthVariant="fullWidth"
          >
            {t("components.AuthComponent.ForgotPassword.popover.cta")}
          </Button>
        ) : (
          <React.Fragment>
            <Button
              onClick={() => setShowPopover(false)}
              colorVariant="secondary"
              name="ForgotPassword-cancel"
            >
              {t("cancel")}
            </Button>

            <Button
              loading={loading}
              dataTestId="handlePasswordResetButton"
              onClick={handlePasswordReset}
              name="ForgotPassword-send"
            >
              {t("send")}
            </Button>
          </React.Fragment>
        )}
      </div>
    </Popover>
  );
};

ForgotPassword.propTypes = propTypes;

export default ForgotPassword;
