import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import store from "store2";
import { useFeatureFlags } from "../../contexts/FeatureFlagContext";
import InputWithButton from "../FormElements/InputWithButton/InputWithButton";
import Key from "../Icons/Key";
import toaster from "../Toaster/Toaster";
import AuthComponentButton from "./AuthComponentButton/AuthComponentButton";
import {
  RECAPTCHA_BROWSER_COMPATIBILITY_LINK,
  STEP_EMAIL_VERIFICATION,
  STEP_LOGIN_CODE,
} from "./constants";
import ForgotPassword from "./ForgotPassword";
import getReCaptchaToken from "./getReCaptchaToken";
import ShowPasswordToggle from "./ShowPasswordToggle";

const PasswordForm = ({
  analyticsEvent,
  email,
  isAPhone,
  login,
  onLogin,
  sendPasswordResetLink,
  t,
  changeStep,
  snowplowEvent,
}) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);

  // WU-1814 Clenup login code (renamed to DEV-4428)
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const showLoginWithCode = isFeatureFlagEnabled("ENVIRONMENT_LOGIN_CODE");

  const inputRef = React.useRef();

  useEffect(() => {
    inputRef.current.focus();

    analyticsEvent("Password step view");
    // This useEffect should only be run on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (event) => {
    if (error) {
      setError(false);
    }

    const value = event.target.value;
    setPassword(value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async () => {
    setError(false);

    if (!password) {
      analyticsEvent("Password step error");
      return setError(true);
    }

    try {
      const reCaptchaToken = await getReCaptchaToken({ action: "login" });

      const shortlistGroups = store.get("default") || [];
      const user = await login({
        email,
        password,
        reCaptchaToken,
        shortlist: {
          name: "default",
          items: shortlistGroups,
        },
      });

      snowplowEvent({ loginMethod: "password" });
      analyticsEvent("loginComplete");

      if (onLogin) {
        return onLogin(user);
      }
    } catch (error) {
      if (error.name === "AuthenticationRequiredError") {
        changeStep(STEP_EMAIL_VERIFICATION);
        return;
      }

      analyticsEvent("Password step error");

      if (error.name === "ReCaptchaValidationError") {
        return toaster.basicToaster.add({
          dataTestId: "reCaptchaErrorToast",
          intent: toaster.intents.danger,
          timeout: 5000,
          title: t("reCaptchaValidationErrorTitle"),
          text: showLoginWithCode
            ? t("reCaptchaValidationErrorLoginCodeText")
            : t("reCaptchaValidationErrorText"),
          buttonLink:
            !showLoginWithCode && RECAPTCHA_BROWSER_COMPATIBILITY_LINK,
          buttonLabel: showLoginWithCode
            ? t("components.AuthComponent.PasswordForm.useLoginCode")
            : t("reCaptchaValidationErrorTextLink"),
          buttonOnClick:
            showLoginWithCode &&
            (() => {
              changeStep(STEP_LOGIN_CODE);
            }),
        });
      }

      setError(true);
    }
  };

  return (
    <div
      data-testid="password-form"
      className="AuthComponent-Form AuthComponent-Form-PasswordForm"
    >
      <InputWithButton
        type={showPassword ? "text" : "password"}
        name="password"
        placeholder={t("components.AuthComponent.PasswordForm.placeholder")}
        btnLabel={t("components.AuthComponent.EmailForm.cta")}
        value={password}
        onChange={onChange}
        onSubmit={onSubmit}
        error={error ? t("errors.doesNotMatchEmail") : null}
        ref={inputRef}
        autoComplete="current-password"
        editable
      />

      <div className="PasswordForm-additionalActionsContainer">
        <ShowPasswordToggle
          t={t}
          onClick={toggleShowPassword}
          showPassword={showPassword}
        />

        <ForgotPassword
          analyticsEvent={analyticsEvent}
          email={email}
          sendPasswordResetLink={sendPasswordResetLink}
          showInDrawer={isAPhone}
          t={t}
        />
        {showLoginWithCode && (
          <AuthComponentButton
            dataTestId="requestLoginCodeButton"
            onClick={() => changeStep(STEP_LOGIN_CODE)}
          >
            <AuthComponentButton.Icon icon={Key} width={18} height={18} />
            <AuthComponentButton.Label>
              {t("components.AuthComponent.PasswordForm.useLoginCode")}
            </AuthComponentButton.Label>
          </AuthComponentButton>
        )}
      </div>
    </div>
  );
};

PasswordForm.propTypes = {
  analyticsEvent: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
  email: PropTypes.string,
  isAPhone: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  onLogin: PropTypes.func,
  sendPasswordResetLink: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  snowplowEvent: PropTypes.func.isRequired,
};

export default PasswordForm;
