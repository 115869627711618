import React from "react";
import PropTypes from "prop-types";
import Eye from "../Icons/Eye";
import EyeClosed from "../Icons/EyeClosed";
import AuthComponentButton from "./AuthComponentButton/AuthComponentButton";

const ShowPasswordToggle = ({
  t,
  className,
  onClick,
  showPassword = false,
  onlyIcon = false,
}) => (
  <AuthComponentButton
    className={className}
    onClick={onClick}
    dataTestId="ShowPasswordToggle"
  >
    <AuthComponentButton.Icon
      icon={showPassword ? EyeClosed : Eye}
      width={18}
      height={18}
    />
    {!onlyIcon && (
      <AuthComponentButton.Label>
        {showPassword
          ? t("components.AuthComponent.PasswordForm.hide")
          : t("components.AuthComponent.PasswordForm.show")}
      </AuthComponentButton.Label>
    )}
  </AuthComponentButton>
);

ShowPasswordToggle.propTypes = {
  t: PropTypes.func,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  showPassword: PropTypes.bool.isRequired,
  onlyIcon: PropTypes.bool,
};

export default ShowPasswordToggle;
