import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import "./AuthPage.scss";
import AuthComponent from "../../../../components/AuthComponent/AuthComponent";
import {
  STEP_EMAIL,
  STEP_EMAIL_VERIFICATION,
  STEP_LOGIN_CODE,
  STEP_PASSWORD,
  STEP_SIGNUP,
} from "../../../../components/AuthComponent/constants";
import getReCaptchaToken from "../../../../components/AuthComponent/getReCaptchaToken";
import ResetPasswordModal from "../../../../components/ResetPasswordModal/ResetPasswordModal";
import SidebarLayout from "../../../../components/SidebarLayout/SidebarLayout";
import toaster from "../../../../components/Toaster/Toaster";
import { AuthContext } from "../../../../contexts/AuthContext";
import { ShortlistContext } from "../../../../contexts/ShortlistContext";
import SpaPagePropTypes from "../../../../types/SpaPage/SpaPagePropTypes";
import sanitizeURL from "../../../../utils/sanitize-url";
import getWFCONFIG from "../../../../utils/WF_CONFIG";
import PageHead from "../../PageHead";
import { getEventLabel, isLandlordPage } from "./auth-page-utils";
import AuthPageHeader from "./AuthPageHeader";
import AuthPageSidebar from "./AuthPageSidebar";
import MobileCoverImage from "./MobileCoverImage";

const WF_CONFIG = getWFCONFIG();

const ENV = WF_CONFIG.NODE_ENV;
const IS_RE_CAPTCHA_ENABLED = ENV === "production" || ENV === "staging";

async function validatePasswordResetToken({
  api,
  t,
  setTokenValid,
  setValidatingToken,
}) {
  setValidatingToken(true);
  const reCaptchaToken = IS_RE_CAPTCHA_ENABLED
    ? await getReCaptchaToken({
        action: "validateToken",
      })
    : "disabled";

  try {
    await api.validateResetPasswordToken({
      reCaptchaToken,
    });
    setTokenValid(true);
    setValidatingToken(false);
  } catch (error) {
    setValidatingToken(false);
    let errorText = t("errors.pleaseTryAgain");
    if (error.name === "InvalidTokenError") {
      errorText = t("components.ErrorMessage.InvalidTokenError");
    }
    if (error.name === "TokenExpiredError") {
      errorText = t("components.ErrorMessage.TokenExpiredError");
    }

    toaster.basicToaster.add({
      timeout: 5000,
      title: t("errors.somethingWentWrong"),
      text: errorText,
      intent: toaster.intents.danger,
    });
  }
}

const AuthPage = ({
  api,
  analytics,
  isAPhone,
  lang,
  query,
  t,
  url,
  params,
  pageData,
}) => {
  const { login, signup } = useContext(AuthContext);
  const { shortlist } = useContext(ShortlistContext);
  const [step, setStep] = useState(STEP_EMAIL);
  const [tokenValid, setTokenValid] = useState(false);
  const [validatingToken, setValidatingToken] = useState(!!query.resetPassword);
  const history = useHistory();
  const authPageURL = url("login");
  const stepRef = useRef(step);
  const authComponentRef = useRef();
  const { listing } = pageData;

  // Decoding is harmless so we can always use it
  const redirect =
    query && query.redirect ? decodeURIComponent(query.redirect) : `/${lang}`;

  // Figure out if tenant or landlord
  const customerType = isLandlordPage({ redirect, lang })
    ? "landlord"
    : "tenant";

  const handleRouteChange = () => {
    const routeStep = document.location.pathname.split("/")[3] || "email";

    if (routeStep === "email" && stepRef.current === STEP_SIGNUP) {
      authComponentRef.current.changeStep(STEP_EMAIL);
    } else if (routeStep === "email" && stepRef.current === STEP_PASSWORD) {
      authComponentRef.current.changeStep(STEP_EMAIL);
    } else if (routeStep === "email" && stepRef.current === STEP_LOGIN_CODE) {
      authComponentRef.current.changeStep(STEP_PASSWORD);
    }
  };

  useEffect(() => {
    const handleSocialAuthError = () => {
      const errorCode = query.social_auth_error_code;

      if (!errorCode) {
        return;
      }

      let errorMessage = t(`spages.authPage.socialAuth.errors.${errorCode}`);
      if (!errorMessage) {
        errorMessage = t("spages.authPage.socialAuth.errors.GenericError");
      }

      const toasterParams = {
        buttonLabel: "Close",
        hasButton: true,
        intent: "danger",
        text: errorMessage,
        timeout: 10000,
      };

      toaster.basicToaster.add(toasterParams);
    };

    handleSocialAuthError();

    if (params.step) {
      history.replace(
        `${authPageURL}?redirect=${encodeURIComponent(redirect)}`,
      );
    }

    if (query.resetPassword) {
      validatePasswordResetToken({
        api,
        t,
        setTokenValid,
        setValidatingToken,
      });
    }

    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, [
    query.resetPassword,
    query.social_auth_error_code,
    params.step,
    history,
    authPageURL,
    redirect,
    api,
    t,
  ]);

  const onStep = (step) => {
    setStep(step);
    stepRef.current = step;

    if (step === STEP_PASSWORD) {
      history.push(
        `${authPageURL}/password?redirect=${encodeURIComponent(redirect)}`,
      );
    } else if (step === STEP_LOGIN_CODE) {
      history.push(
        `${authPageURL}/loginCode?redirect=${encodeURIComponent(redirect)}`,
      );
    } else if (step === STEP_SIGNUP) {
      history.push(
        `${authPageURL}/signup?redirect=${encodeURIComponent(redirect)}`,
      );
    } else if (step === STEP_EMAIL_VERIFICATION) {
      history.push(`${authPageURL}/?redirect=${encodeURIComponent(redirect)}`);
    }
  };

  // @TODO Handle redirect with react-router-dom. See; MQ-652
  const handleRedirect = () => {
    window.history.replaceState(null, null, redirect);
    window.location = sanitizeURL(redirect);
  };

  const showResetPasswordModal = query.resetPassword && tokenValid;
  const autoFocusEmail = !showResetPasswordModal && !validatingToken;

  return (
    <Fragment>
      <PageHead
        noIndex
        title={
          step === STEP_SIGNUP
            ? t("spages.authPage.signupStep.browserTitle")
            : t("spages.authPage.signinStep.browserTitle")
        }
      />

      {showResetPasswordModal && (
        <ResetPasswordModal
          t={t}
          onSuccess={(email) => {
            authComponentRef.current.changeStepToPassword(email);
          }}
          resetPassword={api.resetPassword}
        />
      )}

      <SidebarLayout
        className="AuthPage-SidebarLayout"
        sidebarChildren={
          <AuthPageSidebar
            t={t}
            lang={lang}
            customerType={customerType}
            listing={listing}
          />
        }
        logoHref={url("home")}
      >
        <div className="AuthPage">
          <MobileCoverImage listing={listing} />

          <div className="AuthPage-container">
            <div
              className={classNames({
                "AuthPage-containerContent": step !== STEP_EMAIL_VERIFICATION,
              })}
            >
              <AuthPageHeader step={step} t={t} />
              <div className="AuthComponentWrapper">
                <AuthComponent
                  customerType={customerType}
                  analytics={analytics}
                  emailExists={api.auth.emailExists}
                  eventLabel={getEventLabel({
                    redirect,
                    lang,
                  })}
                  isAPhone={isAPhone}
                  login={login}
                  onLogin={handleRedirect}
                  onStep={onStep}
                  redirect={redirect}
                  ref={authComponentRef}
                  sendPasswordResetLink={api.sendPasswordResetLink}
                  shortlist={shortlist}
                  signup={signup}
                  t={t}
                  url={url}
                  autoFocusEmail={autoFocusEmail}
                  lang={lang}
                  className={classNames({
                    "AuthComponent-EmailVerification":
                      step === STEP_EMAIL_VERIFICATION,
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </SidebarLayout>
    </Fragment>
  );
};

AuthPage.propTypes = SpaPagePropTypes;

export default AuthPage;
