import React, { useEffect, useState } from "react";

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

function withPortal(Component) {
  function WrappedComponent(props, ref) {
    const [portalEl, setPortalEl] = useState(null);

    useEffect(() => {
      const portalEl = document.createElement("div");
      document.body.appendChild(portalEl);
      setPortalEl(portalEl);

      return () => {
        document.body.removeChild(portalEl);
      };
    }, []);

    if (!portalEl) {
      return null;
    }

    return <Component {...props} portalEl={portalEl} ref={ref} />;
  }

  WrappedComponent.displayName = `WithPortal(${getDisplayName(Component)})`;

  return React.forwardRef(WrappedComponent);
}

export default withPortal;
