import { useMutation } from "@tanstack/react-query";
import { useApi } from "../spages/spa/context/ApiContext";

// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
const keys = {
  all: () => ["USERS"],
  updateUser: () => [...keys.all(), "UPDATE"],
};

export default {
  keys,

  useUpdate: (_options = {}) => {
    const api = useApi();

    const options = {
      mutationKey: keys.updateUser(),
      ..._options,
    };

    return useMutation({
      ...options,
      mutationFn: ({ userId, input }) => api.users.update(userId, input),
    });
  },
};
