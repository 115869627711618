import { useMutation, useQuery } from "@tanstack/react-query";
import { useApi } from "../spages/spa/context/ApiContext";

// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
const keys = {
  all: () => ["BILLING_DETAILS"],
  updateBillingDetails: () => [...keys.all(), "UPDATE"],
  createBillingDetails: () => [...keys.all(), "CREATE"],
  deleteBillingDetails: () => [...keys.all(), "DELETE"],
  guessBillingDetails: () => [...keys.all(), "GUESS"],
};

export default {
  keys,

  useUpdate: (_options = {}) => {
    const api = useApi();

    const options = {
      ..._options,
      mutationKey: keys.updateBillingDetails(),
    };

    return useMutation(
      ({ id, input }) => api.users.updateBillingDetails(id, input),
      options,
    );
  },

  useCreate: (_options = {}) => {
    const api = useApi();

    const options = {
      ..._options,
      mutationKey: keys.createBillingDetails(),
    };

    return useMutation(
      ({ input }) => api.users.createBillingDetails(input),
      options,
    );
  },

  useDelete: (_options = {}) => {
    const api = useApi();

    const options = {
      ..._options,
      mutationKey: keys.deleteBillingDetails(),
    };

    return useMutation(({ id }) => api.users.deleteBillingDetails(id), options);
  },

  useGuessBillingDetailsItem: (groupId, options = {}) => {
    const api = useApi();

    return useQuery(
      keys.guessBillingDetails(),
      () => api.users.getGuessBillingDetailsItem(groupId),
      options,
    );
  },
};
