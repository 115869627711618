import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../spages/spa/context/ApiContext";

const keys = {
  all: () => ["SAVED_LISTING_FILTERS"],
  get: () => [...keys.all(), "GET"],
  delete: () => [...keys.all(), "DELETE"],
  create: () => [...keys.all(), "CREATE"],
};

export default {
  keys,

  useGet: (options = {}) => {
    const api = useApi();

    return useQuery(keys.get(), api.filters.getSavedFilters, options);
  },

  useCreate: (_options = {}) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const options = {
      ..._options,
      mutationKey: keys.create(),
      onSuccess: (newProfile) => {
        queryClient.setQueryData(keys.get(), (old) => [
          newProfile,
          ...(old || []),
        ]);

        if (_options.onSuccess) {
          _options.onSuccess(newProfile);
        }
      },
    };

    return useMutation(
      ({ input }) => api.filters.createSavedFilter(input),
      options,
    );
  },

  useDelete: (_options = {}) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const options = {
      ..._options,
      mutationKey: keys.delete(),
      onSuccess: (_, { filterId }) => {
        queryClient.setQueryData(keys.get(), (old) =>
          old.filter((filter) => filter._id !== filterId),
        );

        if (_options.onSuccess) {
          _options.onSuccess(_, { filterId });
        }
      },
    };

    return useMutation(
      ({ filterId }) => api.filters.deleteSavedFilter(filterId),
      options,
    );
  },
};
