import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { getPasswordCriteria } from "../../../utils/validate-password";
import ShowPasswordToggle from "../../AuthComponent/ShowPasswordToggle";
import InputText from "../InputText/InputText";

const InputPassword = ({
  t,
  name,
  placeholder,
  error,
  showPassword,
  toggleShowPassword,
  value: passwordInputValue,
  onFocus,
  enableCustomErrorMessage = true,
  ...props
}) => {
  const passwordCriteria = useMemo(() => getPasswordCriteria(t), [t]);
  const [showPasswordCriteria, setShowPasswordCriteria] = useState(false);
  const [validCriteria, setValidCriteria] = useState(
    passwordCriteria.reduce(
      (acc, criterion) => ({ ...acc, [criterion.label]: false }),
      {},
    ),
  );

  /** Error prop is only set after the user tried to submit the form and we want to show them what went wrong.
   * If the password is empty and the user has tried to submit the form, show the required field error message.
   * If the password is missing any of the validation criteria, they will be highlighted in red so we don't show
   * them other error messages in that case. If something else went wrong, we show the custom error message that was
   * passed in from the parent component.
   * */
  const hasError = !!error;
  let customErrorMessage = null;
  if (hasError && enableCustomErrorMessage) {
    if (
      passwordInputValue === null ||
      passwordInputValue === undefined ||
      passwordInputValue === ""
    ) {
      customErrorMessage = t("errors.required");
    } else if (
      // If the number of valid criteria is >= number of criteria, it means they're all met so we show the custom error message
      // from parent compponent instead
      Object.values(validCriteria).filter((value) => value === true).length >=
      passwordCriteria?.length
    ) {
      customErrorMessage = error;
    }
  }

  useEffect(() => {
    const matchedCriteria = {};
    passwordCriteria.forEach(({ label, regex }) => {
      matchedCriteria[label] = regex.test(passwordInputValue);
    });
    setValidCriteria(matchedCriteria);
  }, [passwordInputValue, passwordCriteria]);

  const getCriteriaColor = (criterium) => {
    if (!passwordInputValue) return "criteriaNotMatched";
    const isCriteriaMatched = validCriteria[criterium];
    if (isCriteriaMatched) {
      return "criteriaMatched";
    }
    return hasError ? "criteriaSubmittedUnmatched" : "criteriaNotMatched";
  };

  const endAdornment = (
    <ShowPasswordToggle
      onClick={toggleShowPassword}
      showPassword={showPassword}
      onlyIcon
      t={t}
    />
  );

  const handleFocus = () => {
    if (onFocus) onFocus();
    setShowPasswordCriteria(true);
  };

  // Prevent spaces
  const onKeyDown = (e) => {
    if (e.keyCode === 32) e.preventDefault();
  };

  // Prevent spaces from being added by pasting a string
  const onKeyUp = (e) => {
    e.target.value = e.target.value.replace(/\s/g, "");
  };

  return (
    <React.Fragment>
      <InputText
        type={showPassword ? "text" : "password"}
        name={name}
        autoComplete="current-password"
        data-testid="PasswordInput"
        placeholder={placeholder}
        error={error}
        endAdornment={endAdornment}
        onFocus={handleFocus}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        {...props}
      />
      {customErrorMessage && (
        <div
          className="PasswordInput-error"
          data-testid="PasswordInput-customErrorMessage"
        >
          {customErrorMessage}
        </div>
      )}
      {showPasswordCriteria && (
        <div className="PasswordInput-criteriaContainer">
          <ul className="PasswordInput-criteria">
            {passwordCriteria.map(({ label }) => (
              <li key={label} className={`criteria ${getCriteriaColor(label)}`}>
                {label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </React.Fragment>
  );
};

InputPassword.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.string,
  showPassword: PropTypes.bool.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  enableCustomErrorMessage: PropTypes.bool,
};

export default InputPassword;
