import React, { forwardRef, useMemo } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "./CodeInput.scss";

export const RE_DIGIT = new RegExp(/^\d+$/);

// https://dominicarrojado.com/posts/how-to-create-your-own-otp-input-in-react-and-typescript-with-tests-part-1/

const CodeInput = forwardRef(
  (
    {
      value = "",
      valueLength = 6,
      onChange,
      dataTestId = "CodeInput",
      className,
    },
    ref,
  ) => {
    const valueItems = useMemo(() => {
      const valueArray = value.split("");
      const items = [];

      for (let i = 0; i < valueLength; i++) {
        const char = valueArray[i];

        if (RE_DIGIT.test(char)) {
          items.push(char);
        } else {
          items.push("");
        }
      }

      return items;
    }, [value, valueLength]);

    const focusToNextInput = (target) => {
      const nextElementSibling = target.nextElementSibling;

      if (nextElementSibling) {
        nextElementSibling.focus();
      }
    };
    const focusToPrevInput = (target) => {
      const previousElementSibling = target.previousElementSibling;

      if (previousElementSibling) {
        previousElementSibling.focus();
      }
    };

    const inputOnChange = (e, idx) => {
      const target = e.target;
      let targetValue = target.value.trim();
      const isTargetValueDigit = RE_DIGIT.test(targetValue);

      if (!isTargetValueDigit && targetValue !== "") {
        return;
      }

      const nextInputEl = target.nextElementSibling;

      // only delete digit if next input element has no value
      if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== "") {
        return;
      }

      targetValue = isTargetValueDigit ? targetValue : " ";

      const targetValueLength = targetValue.length;

      if (targetValueLength === 1) {
        const newValue =
          value.substring(0, idx) + targetValue + value.substring(idx + 1);

        onChange(newValue.replaceAll(" ", ""));

        if (!isTargetValueDigit) {
          return;
        }

        focusToNextInput(target);

        // If copy paste full code
      } else if (targetValueLength === valueLength) {
        onChange(targetValue);

        target.blur();
      }
    };

    // Do note that the change event will not be triggered if we try to press backspace where the input box is already empty.
    // We will have to rely on the keydown event instead
    const inputOnKeyDown = (e) => {
      const { key, target } = e;
      const targetValue = target.value;

      if (key === "ArrowRight" || key === "ArrowDown") {
        e.preventDefault();
        return focusToNextInput(target);
      }

      if (key === "ArrowLeft" || key === "ArrowUp") {
        e.preventDefault();
        return focusToPrevInput(target);
      }

      // keep the selection range position if the same digit was typed
      target.setSelectionRange(0, targetValue.length);

      if (e.key !== "Backspace" || target.value !== "") {
        return;
      }

      focusToPrevInput(target);
    };

    const inputOnFocus = (e) => {
      const { target } = e;

      // keep focusing back until previous input
      // element has value
      const prevInputEl = target.previousElementSibling;

      if (prevInputEl && prevInputEl.value === "") {
        return prevInputEl.focus();
      }

      target.setSelectionRange(0, target.value.length);
    };

    return (
      <div className={cn("CodeInput", className)} data-testid={dataTestId}>
        {valueItems.map((digit, idx) => (
          <input
            data-testid={`CodeInputInput-${idx}`}
            ref={idx === 0 ? ref : null}
            key={idx}
            type="text"
            inputMode="numeric"
            autoComplete="off"
            pattern="\d{1}"
            maxLength={valueLength}
            className="CodeInput-input"
            value={digit}
            onChange={(e) => inputOnChange(e, idx)}
            onKeyDown={inputOnKeyDown}
            onFocus={inputOnFocus}
          />
        ))}
      </div>
    );
  },
);

CodeInput.propTypes = {
  value: PropTypes.string,
  valueLength: PropTypes.number,
  onChange: PropTypes.func,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

export default CodeInput;
