import React from "react";
import PropTypes from "prop-types";

const Trash = ({ className, width = 17, height = 17 }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8218 3.12344L10.8218 1.52344H6.92178L5.92178 3.12344H3.32178L3.62178 4.92344H14.2218L14.5218 3.12344H11.8218Z"
      fill="#24272E"
    />
    <path
      d="M13.422 6.82227L12.622 14.2223C12.522 15.0223 11.822 15.6223 10.922 15.6223H7.02197C6.22197 15.6223 5.42197 15.0223 5.32197 14.2223L4.52197 6.82227"
      fill="white"
    />
    <path
      d="M14.2221 5.82227H3.62207L3.72207 7.02227L4.52207 14.3223C4.72207 15.5223 5.72207 16.4223 6.92207 16.4223H10.7221C11.9221 16.4223 13.0221 15.5223 13.1221 14.3223L14.1221 6.92227L14.2221 5.82227ZM11.8221 14.1223C11.7221 14.6223 11.3221 14.9223 10.8221 14.9223H7.02207C6.52207 14.9223 6.12207 14.6223 6.12207 14.1223L5.22207 6.82227H12.6221L11.8221 14.1223Z"
      fill="#24272E"
    />
  </svg>
);

Trash.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Trash;
