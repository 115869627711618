import { useEffect } from "react";

function useKeyDown({ cb, keyDown, isDisabled }) {
  useEffect(() => {
    const KeyMap = {
      Enter: 13,
      Escape: 27,
      ArrowUp: 38,
      ArrowDown: 40,
    };

    const onKeyDown = (e) => {
      if (!isDisabled && e.which === KeyMap[keyDown]) {
        // eslint-disable-next-line callback-return
        cb(e);
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
    // We don't need to re-render when other props change
  }, [isDisabled, cb, keyDown]);
}

export default useKeyDown;
