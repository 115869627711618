import React from "react";
import PropTypes from "prop-types";

const SyncIcon = ({ className, width = 18, height = 18 }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 18"
      width={width}
      height={height}
    >
      <path fill="#24272E" d="M1.5 16v-6h6.1l-6.1 6zm15-14v6h-6.1l6.1-6z" />
      <path
        fill="#24272E"
        d="M3.8 8C4.3 5.6 6.4 3.8 9 3.8s4.7 1.8 5.1 4.2h1.5c-.5-3.2-3.3-5.8-6.6-5.8S2.8 4.8 2.3 8h1.5zm10.3 2c-.5 2.4-2.6 4.2-5.1 4.2-2.5 0-4.7-1.8-5.2-4.2H2.3c.5 3.2 3.3 5.8 6.7 5.8 3.4 0 6.2-2.5 6.7-5.8h-1.6z"
      />
    </svg>
  );
};

SyncIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SyncIcon;
