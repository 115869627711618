import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  className: PropTypes.string,
};

const Padlock = ({ className }) => {
  return (
    <svg
      className={className}
      width="11"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.52466 5.31128c.51289.21693.98723.50189 1.41744.84936V3.70447C8.9421 1.66196 7.28015 0 5.23764 0h-.05699C3.13998 0 1.47803 1.66196 1.47803 3.70447v2.45617c.43019-.34745.90451-.63241 1.41744-.84936.13788-.05883.27944-.11215.421-.15995V3.70447c0-1.02953.83649-1.86602 1.86602-1.86602h.05699c1.02953 0 1.86602.83649 1.86602 1.86602v1.4487c.1397.04596.28128.09928.41916.15811z"
        fill="#24272E"
      />
      <path
        d="M0 10.7901c0 2.8772 2.33299 5.2102 5.21016 5.2102s5.21014-2.3312 5.21014-5.2102c0-1.41374-.56438-2.69698-1.47807-3.63644-.51476-.52762-1.13984-.9468-1.83845-1.22071-.58646-.22981-1.22626-.35483-1.8936-.35483-.66736 0-1.30714.12502-1.8936.35483-.69861.27209-1.32368.69125-1.83845 1.22071C.562565 8.09312 0 9.37636 0 10.7901zm3.86808-1.05157c.01838-.71147.59749-1.29059 1.30897-1.30715.75559-.01839 1.37516.59016 1.37516 1.34207 0 .07907-.00735.15626-.02022.22985-.06622.3805-.29231.7078-.60856.9063-.13053.0828-.1967.2372-.16359.3861l.39896 1.8918c.02392.1139-.0625.2224-.18018.2224H4.44169c-.11766 0-.20408-.1066-.18017-.2224l.39893-1.8918c.03125-.1508-.03309-.3052-.1636-.3879-.31622-.1986-.54235-.524-.60855-.9064-.01471-.08454-.02204-.17278-.02022-.26287z"
        fill="#24272E"
      />
    </svg>
  );
};

Padlock.propTypes = propTypes;

export default Padlock;
