import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "./AuthComponentButton.scss";

const AuthComponentButton = ({ children, onClick, dataTestId }) => {
  return (
    <button
      data-testid={dataTestId}
      type="button"
      onClick={onClick}
      className="AuthComponentButton"
    >
      {children}
    </button>
  );
};

AuthComponentButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
};

AuthComponentButton.Icon = ({ icon: Icon, width, height, className }) => {
  return (
    <Icon
      width={width}
      height={height}
      className={cn("AuthComponentButton-icon", className)}
    />
  );
};

AuthComponentButton.Icon.propTypes = {
  icon: PropTypes.element,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

AuthComponentButton.Label = ({ children, className }) => {
  return (
    <span className={cn("AuthComponentButton-label", className)}>
      {children}
    </span>
  );
};

AuthComponentButton.Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default AuthComponentButton;
