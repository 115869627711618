import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { users } from "@wunderflats/constants";
import qs from "qs";
import { Form, useField } from "react-final-form";
import store from "store2";
import { signupValidation } from "../../contexts/AuthContext";
import { useFeatureFlags } from "../../contexts/FeatureFlagContext";
import useMedia from "../../hooks/useMedia";
import { getSystemLanguages } from "../../routes";
import {
  getCountryCodeFromRedirectUrl,
  getLanguageCodeFromLang,
} from "../../spages/spa/Pages/AuthPage/auth-page-utils";
import { isInViewport } from "../../utils/dom";
import InputPassword from "../FormElements/InputPassword/InputPassword";
import InputText from "../FormElements/InputText/InputText";
import InputWithButton from "../FormElements/InputWithButton/InputWithButton";
import InputWrapper from "../FormElements/InputWrapper/InputWrapper";
import PhoneNumberInput from "../FormElements/PhoneNumberInput/PhoneNumberInput";
import InfoIcon from "../Icons/InfoIcon";
import InputTooltip from "../InputTooltip/InputTooltip";
import StyledCheckbox from "../StyledCheckbox/StyledCheckbox";
import toaster from "../Toaster/Toaster";
import {
  RECAPTCHA_BROWSER_COMPATIBILITY_LINK,
  STEP_EMAIL,
  STEP_EMAIL_VERIFICATION,
} from "./constants";
import getReCaptchaToken from "./getReCaptchaToken";
import ShowPasswordToggle from "./ShowPasswordToggle";

const errorMessageParser = (t, error, customErrorMessages = {}) => {
  const [errorType, expectation] = error.split(":");

  // Check custom errors
  if (customErrorMessages[errorType]) {
    return customErrorMessages[errorType];
  }

  const message = t(`errors.${errorType}`, { expectation }) || error;

  return message || `errors.${errorType}`;
};

const useError = (name) => {
  const {
    meta: { touched, error, submitError },
  } = useField(name, {
    subscription: { touched: true, error: true, submitError: true },
  });

  return touched && (error || submitError);
};

const onFocusScrollIntoView = (wrapperComponentRef) => {
  if (isInViewport(wrapperComponentRef.current)) {
    return;
  }

  // We're adding the delay because we need to the browser calculate
  // the positions after the virtual keyboard is visible.
  setTimeout(() => {
    wrapperComponentRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }, 200);
};

const MergedNameInputs = ({ t, onFocus }) => {
  const { meta: firstNameMeta, input: firstNameInput } = useField("firstName");
  const { meta: lastNameMeta, input: lastNameInput } = useField("lastName");

  let firstNameErrorMessage = useError("firstName");
  firstNameErrorMessage = firstNameErrorMessage
    ? t(`errors.${firstNameErrorMessage}`)
    : null;

  let lastNameErrorMessage = useError("lastName");
  lastNameErrorMessage = lastNameErrorMessage
    ? t(`errors.${lastNameErrorMessage}`)
    : null;

  return (
    <InputWrapper
      dataTestId="NamesInputWrapper"
      error={firstNameErrorMessage || lastNameErrorMessage}
      label={t("components.AuthComponent.SignupForm.inputs.name")}
      name="name"
      t={t}
      WrapperComponent="div"
      onFocus={onFocus}
      showAsterisk
    >
      <div className="SignupForm-inputGroup">
        <InputText
          name="firstName"
          placeholder={t(
            "components.AuthComponent.SignupForm.inputs.firstName",
          )}
          error={
            (firstNameMeta.error || firstNameMeta.submitError) &&
            firstNameMeta.touched
          }
          {...firstNameInput}
        />
        <InputText
          name="lastName"
          placeholder={t("components.AuthComponent.SignupForm.inputs.lastName")}
          error={
            (lastNameMeta.error || lastNameMeta.submitError) &&
            lastNameMeta.touched
          }
          {...lastNameInput}
        />
      </div>
    </InputWrapper>
  );
};

MergedNameInputs.propTypes = {
  onFocus: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const PhoneNumberInputWithWrapper = ({
  t,
  lang,
  onFocus,
  required = false,
  isAPhone,
  showToolTip = false,
  countryCode,
}) => {
  const { isFeatureFlagEnabled } = useFeatureFlags();

  const isNewRegistrationFlowEnabled = isFeatureFlagEnabled(
    "ENVIRONMENT_NEW_REGISTRATION_FLOW",
  );
  const { input, meta } = useField("phone");
  const isSmallScreen = useMedia("(max-width: 768px)");

  let errorMessage = useError("phone");
  errorMessage = errorMessage ? errorMessageParser(t, errorMessage) : null;

  const tooltipContent = (
    <div className="phoneTooltipContent">
      <div className="phoneTooltipContent-section">
        <div className="phoneTooltipContent-title">
          {t(
            "components.AuthComponent.SignupForm.inputs.phoneNumber.tooltip.content1.title",
          )}
        </div>
        <div className="phoneTooltipContent-text">
          {t(
            "components.AuthComponent.SignupForm.inputs.phoneNumber.tooltip.content1.text",
          )}
        </div>
      </div>
      <div className="phoneTooltipContent-section">
        <div className="phoneTooltipContent-title">
          {t(
            "components.AuthComponent.SignupForm.inputs.phoneNumber.tooltip.content2.title",
          )}
        </div>
        <div className="phoneTooltipContent-text">
          {t(
            "components.AuthComponent.SignupForm.inputs.phoneNumber.tooltip.content2.text",
          )}
        </div>
      </div>
    </div>
  );

  const subLabel = (
    <InputTooltip
      popoverClassName="SignupPhoneTooltip-popover"
      contentComponent={tooltipContent}
      tooltipIcon={
        <div data-testid="SignupPhoneTooltipWrapper">
          <InfoIcon />
          <span className="phoneNumber-sublabel-text">
            {t(
              "components.AuthComponent.SignupForm.inputs.phoneNumber.tooltip.label",
            )}
          </span>
        </div>
      }
      placement={isSmallScreen ? "top" : "left"}
      usePortal={!isAPhone}
      closeButtonText={t(
        "components.AuthComponent.SignupForm.inputs.passwordTooltip.closeButton",
      )}
    />
  );

  return (
    <InputWrapper
      className="InputWrapperPhone"
      name="phone"
      t={t}
      data-testid="PhoneInputWrapper"
      label={t("components.AuthComponent.SignupForm.inputs.phoneNumber")}
      subLabel={required || showToolTip ? subLabel : "Optional"}
      error={errorMessage}
      WrapperComponent="div"
      onFocus={onFocus}
      showAsterisk={required}
    >
      <PhoneNumberInput
        name="phone"
        t={t}
        error={(meta.error || meta.submitError) && meta.touched}
        countryCode={
          isNewRegistrationFlowEnabled
            ? getLanguageCodeFromLang(lang)
            : countryCode
        }
        {...input}
      />
    </InputWrapper>
  );
};

PhoneNumberInputWithWrapper.propTypes = {
  onFocus: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  lang: PropTypes.string,
  required: PropTypes.bool,
  isAPhone: PropTypes.bool.isRequired,
  showToolTip: PropTypes.bool,
  countryCode: PropTypes.string,
};

const PasswordInputWithWrapper = ({
  t,
  toggleShowPassword,
  showPassword,
  onFocus,
  isAPhone,
}) => {
  const { input, meta } = useField("password");
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const isWeakPasswordFeatureFlagEnabled = isFeatureFlagEnabled(
    "ENVIRONMENT_WEAK_PASSWORDS",
  );

  let errorMessage = useError("password");

  // TODO-14774 Remove feature flag
  if (!isWeakPasswordFeatureFlagEnabled) {
    errorMessage = errorMessage ? errorMessageParser(t, errorMessage) : null;
  }

  const passwordTooltipContent = (
    <div className="passwordTooltipContent">
      <h4 className="passwordTooltipContent-title">
        {t("components.AuthComponent.SignupForm.inputs.passwordTooltip.title")}
      </h4>
      <ul className="passwordTooltipContent-list">
        <li>
          {t(
            "components.AuthComponent.SignupForm.inputs.passwordTooltip.firstRule",
          )}
        </li>
        <li>
          {t(
            "components.AuthComponent.SignupForm.inputs.passwordTooltip.secondRule",
          )}
        </li>
        <li>
          {t(
            "components.AuthComponent.SignupForm.inputs.passwordTooltip.thirdRule",
          )}
        </li>
        <li>
          {t(
            "components.AuthComponent.SignupForm.inputs.passwordTooltip.fourthRule",
          )}
        </li>
        <li>
          {t(
            "components.AuthComponent.SignupForm.inputs.passwordTooltip.fifthRule",
          )}
        </li>
      </ul>
    </div>
  );

  return (
    <InputWrapper
      className="InputWrapper-passwordInput InputWrapper--hideBorder"
      dataTestId="PasswordInputWrapper"
      error={!isWeakPasswordFeatureFlagEnabled && errorMessage}
      label={t("components.AuthComponent.SignupForm.inputs.password")}
      name="password"
      t={t}
      onFocus={onFocus}
      showAsterisk
    >
      {!isWeakPasswordFeatureFlagEnabled && (
        <React.Fragment>
          <InputText
            type={showPassword ? "text" : "password"}
            name="password"
            autoComplete="current-password"
            placeholder={t(
              "components.AuthComponent.SignupForm.inputs.passwordPlaceholder.atLeast8characters",
            )}
            error={(meta.error || meta.submitError) && meta.touched}
            {...input}
          />
          <ShowPasswordToggle
            className="SignupForm-passwordToggleButton"
            onClick={toggleShowPassword}
            showPassword={showPassword}
            onlyIcon
          />
        </React.Fragment>
      )}

      {isWeakPasswordFeatureFlagEnabled && (
        <InputPassword
          name="password"
          placeholder={t(
            "components.AuthComponent.SignupForm.inputs.passwordPlaceholder",
          )}
          showPassword={showPassword}
          toggleShowPassword={toggleShowPassword}
          t={t}
          error={meta.submitFailed && errorMessage}
          enableCustomErrorMessage={false}
          {...input}
        />
      )}

      {!isWeakPasswordFeatureFlagEnabled && (
        <InputTooltip
          popoverClassName="SignupPasswordTooltip-popover"
          contentComponent={passwordTooltipContent}
          tooltipIcon={<InfoIcon />}
          placement={isAPhone ? "left-start" : "right"}
          usePortal={!isAPhone}
          closeButtonText={t(
            "components.AuthComponent.SignupForm.inputs.passwordTooltip.closeButton",
          )}
        />
      )}
    </InputWrapper>
  );
};

PasswordInputWithWrapper.propTypes = {
  onFocus: PropTypes.func.isRequired,
  showPassword: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
  isAPhone: PropTypes.bool.isRequired,
  isWeakPasswordFeatureFlagEnabled: PropTypes.bool.isRequired,
};

const TosCheckbox = ({ url, t }) => {
  const { input } = useField("tos", { type: "checkbox" });

  const urlParams = new URLSearchParams(document.location.search);
  const redirectUrlParam = urlParams.get("redirect");
  const isSupportingUkraine = redirectUrlParam?.includes("support-ukraine");

  let errorMessage = useError("tos");

  const customErrorMessages = {
    required: t("components.AuthComponent.SignupForm.tosRequiredError"),
  };

  errorMessage = errorMessage
    ? errorMessageParser(t, errorMessage, customErrorMessages)
    : null;

  const label = t("components.AuthComponent.SignupForm.inputs.tos", {
    privacyPolicy: "#",
    termsAndConditions: "#",
  }).split("#");

  label.splice(
    1,
    0,
    <a
      className="SignupForm-tosLink"
      href={url("privacypolicy")}
      key="privacyPolicy"
      target="_blank"
    >
      {t("components.AuthComponent.SignupForm.privacyPolicy")}
    </a>,
  );

  label.splice(
    3,
    0,
    <a
      data-testid="tos-link"
      className="SignupForm-tosLink"
      href={isSupportingUkraine ? url("tosUk") : url("tos")}
      key="termsAndConditions"
      target="_blank"
    >
      {t("components.AuthComponent.SignupForm.termsAndConditions")}
    </a>,
  );

  label.push(
    <span key="asterisk" className="asterisk">
      *
    </span>,
  );

  return (
    <div
      className="SignupForm-tosCheckbox"
      data-testid="SignupForm-tosCheckbox"
    >
      <StyledCheckbox
        {...input}
        label={label}
        data-testid="tosCheckbox"
        className="SignupForm-checkboxLabel"
        error={errorMessage}
      />
    </div>
  );
};

TosCheckbox.propTypes = {
  url: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const MarketingConsentCheckbox = ({ t }) => {
  const { input } = useField("marketingConsent", {
    type: "checkbox",
    defaultValue: false,
  });

  return (
    <StyledCheckbox
      shape="circle"
      label={t("components.AuthComponent.SignupForm.marketingConsent")}
      data-testid="marketingConsentCheckbox"
      className="SignupForm-checkboxLabel"
      {...input}
    />
  );
};

MarketingConsentCheckbox.propTypes = {
  t: PropTypes.func.isRequired,
};

const EmailInputWithWrapper = ({ t, email, onEmailEdit }) => {
  let errorMessage = useError("email");
  errorMessage = errorMessage ? errorMessageParser(t, errorMessage) : null;

  return (
    <InputWrapper
      className="InputWrapperEmail"
      error={errorMessage}
      label={t("components.AuthComponent.SignupForm.inputs.email")}
      name="email"
      showAsterisk
    >
      <InputWithButton
        type="email"
        name="email"
        value={email}
        onEdit={onEmailEdit}
        editable={false}
        autoComplete="off"
      />
    </InputWrapper>
  );
};

EmailInputWithWrapper.propTypes = {
  email: PropTypes.string.isRequired,
  onEmailEdit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const SignupForm = ({
  analyticsEvent,
  changeStep,
  customerType,
  email,
  getGAClientId,
  getGASessionId,
  lang,
  redirect,
  signup,
  t,
  url,
  isAPhone,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { featureFlags, isFeatureFlagEnabled } = useFeatureFlags();

  const isWeakPasswordFeatureFlagEnabled = isFeatureFlagEnabled(
    "ENVIRONMENT_WEAK_PASSWORDS",
  );

  const isPhoneNumberRequired = customerType === "landlord";

  useEffect(() => {
    analyticsEvent("Signup step view");
  }, [analyticsEvent]);

  const toggleShowPassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  const onEmailEdit = () => {
    changeStep(STEP_EMAIL);
  };

  const showToasterMessage = (title) => {
    toaster.basicToaster.add({
      title,
      timeout: 5000,
      intent: toaster.intents.danger,
    });
  };

  const onSubmit = async (values) => {
    try {
      const [gaClientId, gaSessionId] = await Promise.all([
        getGAClientId(),
        getGASessionId(),
      ]);

      const reCaptchaToken = await getReCaptchaToken({ action: "signup" });

      let redirectURL = null;
      let redirectQuery = null;
      if (redirect) {
        redirectURL = encodeURI(`${window.location.origin}${redirect}`);
        redirectQuery = qs.parse(redirect.split("?")[1]);
      }

      const shortlistGroups = store.get("default") || [];

      // We don't want to send number with dial code only
      const phone = values?.phone?.split(" ")[1]?.length
        ? values.phone
        : undefined;

      const language = getSystemLanguages({
        globalFeatureFlags: featureFlags,
      }).includes(lang)
        ? lang
        : "en";

      let userType;

      switch (redirectQuery?.action) {
        case "explore":
          userType = users.USER_TYPES.EXPLORER_LANDLORD;
          break;
        case "createListing":
          userType = users.USER_TYPES.POTENTIAL_LANDLORD;
          break;
        default:
          userType = undefined;
          break;
      }

      await signup(
        {
          ...values,
          phone,
          ...{
            email,
            gaClientId,
            gaSessionId,
            redirectURL,
            language,
            reCaptchaToken,
          },
          shortlist: {
            name: "default",
            items: shortlistGroups,
          },
          userType,
        },
        isPhoneNumberRequired,
      );
    } catch (error) {
      if (error.name === "AuthenticationRequiredError") {
        changeStep(STEP_EMAIL_VERIFICATION);
        return;
      }

      if (error.name === "ReCaptchaValidationError") {
        return toaster.basicToaster.add({
          intent: toaster.intents.danger,
          timeout: 5000,
          title: t("reCaptchaValidationErrorTitle"),
          text: t("reCaptchaValidationErrorText"),
          buttonLink: RECAPTCHA_BROWSER_COMPATIBILITY_LINK,
          buttonLabel: t("reCaptchaValidationErrorTextLink"),
        });
      }

      if (error.name === "ValidationError") {
        return error.fields;
      }

      if (error.name === "UserAlreadyExistsError") {
        showToasterMessage(t(`errors.userAlreadyExists`));
      }

      analyticsEvent("Signup step error");
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={signupValidation({
        isPhoneNumberRequired,
        isWeakPasswordFeatureFlagEnabled,
        t,
      })}
      render={({ errors = {}, handleSubmit, submitting }) => {
        return (
          <form
            data-testid="signup-form"
            className="AuthComponent-Form SignupForm AuthComponent-Form--stickyFooter"
            onSubmit={(event) => {
              analyticsEvent("Signup step submit");

              // We're also dispatching instant validation errors on submit
              if (Object.keys(errors).length > 0) {
                analyticsEvent("Signup step error");
              }

              handleSubmit(event);
            }}
          >
            <div className="SignupForm-inputsContainer">
              <EmailInputWithWrapper
                t={t}
                email={email}
                onEmailEdit={onEmailEdit}
              />

              <MergedNameInputs t={t} onFocus={onFocusScrollIntoView} />

              <PhoneNumberInputWithWrapper
                t={t}
                lang={lang}
                onFocus={onFocusScrollIntoView}
                required={isPhoneNumberRequired}
                showToolTip={customerType === "landlord"}
                isAPhone={isAPhone}
                countryCode={getCountryCodeFromRedirectUrl(redirect)}
              />

              <PasswordInputWithWrapper
                t={t}
                toggleShowPassword={toggleShowPassword}
                showPassword={showPassword}
                onFocus={onFocusScrollIntoView}
                isAPhone={isAPhone}
              />

              <TosCheckbox url={url} t={t} />
              <MarketingConsentCheckbox t={t} />
            </div>

            <div className="SignupForm-footerContainer">
              <button
                data-testid="signup-submit"
                className="SignupForm-submitButton"
                disabled={submitting}
              >
                {t("components.AuthComponent.SignupForm.continue")}
              </button>
            </div>
          </form>
        );
      }}
    />
  );
};

SignupForm.propTypes = {
  analyticsEvent: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
  customerType: PropTypes.oneOf(["landlord", "tenant"]).isRequired,
  email: PropTypes.string,
  getGAClientId: PropTypes.func.isRequired,
  getGASessionId: PropTypes.func.isRequired,
  lang: PropTypes.string,
  redirect: PropTypes.string,
  signup: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  isAPhone: PropTypes.bool,
};

export default SignupForm;
