import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  className: PropTypes.string,
};

const Student = ({ className, ...props }) => {
  return (
    <svg
      className={className}
      width="18"
      height="14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.363 4.34033H3.62207v5.02273c0 1.15904 2.39545 2.93634 5.40909 2.93634 2.93634 0 5.40914-1.7 5.40914-2.93634V4.34033h-.0773z"
        fill="#fff"
        stroke="#343434"
        strokeMiterlimit="10"
      />
      <path
        d="M14.1312 2.56299H3.77661v5.02273c0 1.15909 2.31818 2.93638 5.17727 2.93638 2.85912 0 5.17732-1.70002 5.17732-2.93638V2.56299z"
        fill="#fff"
        stroke="#343434"
        strokeMiterlimit="10"
      />
      <path
        d="M16.9903 4.3401l-6.8772 3.24546c-.6955.30909-1.46822.30909-2.16368 0L1.07215 4.3401c-.309091-.15454-.309091-.61818 0-.77273L8.10397.862828c.61818-.231818 1.23636-.231818 1.85454 0L16.9903 3.56737c.3091.15455.3091.61819 0 .77273z"
        fill="#fff"
        stroke="#343434"
        strokeMiterlimit="10"
      />
      <path
        d="M1.4585 12.3764V5.11279"
        stroke="#343434"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M1.45853 9.44043L.222168 13.3813H2.6949L1.45853 9.44043z"
        fill="#343434"
      />
      <path
        d="M1.38125 11.2947c.46944 0 .85-.3805.85-.85 0-.46942-.38056-.84997-.85-.84997-.469442 0-.85.38055-.85.84997 0 .4695.380558.85.85.85z"
        fill="#343434"
      />
    </svg>
  );
};

Student.propTypes = propTypes;

export default Student;
