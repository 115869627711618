import React from "react";
import PropTypes from "prop-types";
import CoverImage from "../../../../components/CoverImage/CoverImage";
import ListingCoverImage from "../../../../components/ListingCoverImage/ListingCoverImage";

const MobileCoverImage = ({ listing }) => {
  if (listing) {
    return <ListingCoverImage {...listing} className="AuthPage-coverImage" />;
  }

  return (
    <CoverImage
      className="AuthPage-coverImage MissingPhonePage-coverImage"
      src="/public/images/auth-page/cover-image-750.png"
      srcSet="
      /public/images/auth-page/cover-image-375.png 375w,
      /public/images/auth-page/cover-image-750.png 750w,
      /public/images/auth-page/cover-image-750.png 1500w,
      /public/images/auth-page/cover-image-2058.png 2058w
    "
      sizes="100vw"
    />
  );
};

MobileCoverImage.propTypes = {
  listing: PropTypes.object,
};

export default MobileCoverImage;
