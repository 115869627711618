import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import { CSSTransition } from "react-transition-group";
import useKeyDown from "../../hooks/useKeyDown";
import useMedia from "../../hooks/useMedia";
import { findByType } from "../../utils/dom";
import withPortal from "../../utils/with-portal";
import Drawer from "../Drawer/Drawer";
import CrossIcon from "../Icons/CrossIcon";
import {
  CardBody,
  CardButtonGroup,
  CardFooter,
  CardHeader,
} from "./Subcomponents";
import "./CardModal.scss";

const CardModal = ({
  visible,
  onHide,
  children,
  scrollContainerRef,
  hideOnClickOutside = true,
  hideOnEscape = true,
  hidable = true,
  autoHeight = false,
  portalEl,
  className,
  drawerClassName,
  drawerChildrenClassName,
  drawerBreakpoint = 768,
  showCloseButton = true,
}) => {
  const backdropRef = useRef(null);
  const cardRef = useRef(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const isPastBreakpoint = useMedia(`(max-width: ${drawerBreakpoint}px)`);

  const header = findByType(children, CardHeader);
  const body = findByType(children, CardBody);
  const footer = findByType(children, CardFooter);

  if (!body) {
    throw new Error("CardModal.CardBody is required");
  }

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
      document.documentElement.classList.add("card-modal-open");

      return () => {
        document.body.style.overflow = "";
        document.documentElement.classList.remove("card-modal-open");
      };
    }
  }, [visible]);

  useKeyDown({ cb: onHide, keyDown: "Escape", isDisabled: !hideOnEscape });

  useEffect(() => {
    if (isPastBreakpoint) {
      setShowDrawer(true);
    } else {
      setShowDrawer(false);
    }
  }, [isPastBreakpoint]);

  if (showDrawer) {
    return (
      <Drawer
        isVisible={visible}
        onCloseDrawer={onHide}
        className={drawerClassName}
        childrenClassName={classnames(
          "CardModal-drawerChildren",
          drawerChildrenClassName,
        )}
      >
        <div className="CardModal-scrollContainer" ref={scrollContainerRef}>
          {header}
          {body}
        </div>
        {footer}
      </Drawer>
    );
  }

  const onClickOutside = (e) => {
    if (cardRef.current.contains(e.target) && hidable) {
      return;
    }
    if (hideOnClickOutside) {
      onHide();
    }
  };

  return ReactDOM.createPortal(
    <CSSTransition appear in={visible} timeout={300} mountOnEnter unmountOnExit>
      <div
        className={classnames("CardModal-backdrop", className, {
          "CardModal-backdrop--autoHeight": autoHeight,
        })}
        ref={backdropRef}
        onClick={onClickOutside}
      >
        <div
          className="CardModal-card"
          ref={cardRef}
          data-testid="CardModal-card"
        >
          {showCloseButton && (
            <span className="CardModal-closeButtonContainer">
              <button
                data-testid="CardModal-closeButton"
                className="CardModal-closeButton"
                onClick={onHide}
                disabled={!hidable}
              >
                <CrossIcon variant="gradient" />
              </button>
            </span>
          )}

          <div className="CardModal-scrollContainer" ref={scrollContainerRef}>
            {header}
            {body}
          </div>
          {footer}
        </div>
      </div>
    </CSSTransition>,
    portalEl,
  );
};

CardModal.propTypes = {
  visible: PropTypes.bool,
  onHide: PropTypes.func,
  children: PropTypes.any,
  scrollContainerRef: PropTypes.any,
  hideOnClickOutside: PropTypes.bool,
  hideOnEscape: PropTypes.bool,
  hidable: PropTypes.bool,
  autoHeight: PropTypes.bool,
  portalEl: PropTypes.any,
  className: PropTypes.string,
  drawerClassName: PropTypes.string,
  drawerChildrenClassName: PropTypes.string,
  drawerBreakpoint: PropTypes.any,
  showCloseButton: PropTypes.bool,
};

const defaultExport = withPortal(CardModal);
defaultExport.Header = CardHeader;
defaultExport.Body = CardBody;
defaultExport.Footer = CardFooter;
defaultExport.ButtonGroup = CardButtonGroup;

export default defaultExport;
