import React from "react";
import PropTypes from "prop-types";

const GoogleIcon = ({ width = 30, height = 30 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={width}
    height={height}
  >
    <g clipPath="url(#clip0)">
      <path
        fill="#FBBB00"
        d="M9.432 17.086l-.696 2.6-2.544.053A9.956 9.956 0 015 15a9.95 9.95 0 011.118-4.599l2.266.415.992 2.252A5.944 5.944 0 009.056 15c0 .734.132 1.438.376 2.086z"
      />

      <path
        fill="#518EF8"
        d="M24.825 13.132a10.02 10.02 0 01-.044 3.956 9.998 9.998 0 01-3.52 5.71h-.001l-2.854-.146-.403-2.52a5.96 5.96 0 002.564-3.044H15.22v-3.956h9.605z"
      />

      <path
        fill="#28B446"
        d="M21.26 22.798h.001A9.958 9.958 0 0115.001 25a9.999 9.999 0 01-8.809-5.26l3.241-2.654a5.946 5.946 0 008.57 3.045l3.258 2.667z"
      />

      <path
        fill="#F14336"
        d="M21.383 7.302l-3.24 2.652a5.948 5.948 0 00-8.767 3.114L6.12 10.401A9.998 9.998 0 0115 5c2.426 0 4.651.864 6.383 2.302z"
      />
    </g>

    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M5 5h20v20H5z" />
      </clipPath>
    </defs>
  </svg>
);

GoogleIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default GoogleIcon;
