import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import trackable from "../../analytics/trackables/trackable";
import { useCurrencies } from "../../spages/spa/context/CurrenciesContext";
import CoinsIcon from "../Icons/Coins";
import InfoIcon from "../Icons/InfoIcon";
import StudentIcon from "../Icons/Student";
import InformationTable from "../InformationTable/InformationTable";
import { placements } from "../Popover/Popover";
import TenantServiceFeeInfoPopOver from "../TenantServiceFeeInfoPopOver/TenantServiceFeeInfoPopOver";

import "./ListingCostSummary.scss";

const propTypes = {
  currency: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  deposit: PropTypes.number,
  finalCleaningFee: PropTypes.number,
  t: PropTypes.func.isRequired,
  restrictions: PropTypes.object,
  lang: PropTypes.string,
  sftValue: PropTypes.number,
  tracker: PropTypes.object.isRequired,
  tenantServiceFeeViewedCallback: PropTypes.func,
};

const ListingCostSummary = ({
  currency,
  price,
  deposit,
  finalCleaningFee,
  t,
  restrictions,
  lang,
  sftValue,
  tenantServiceFeeViewedCallback,
}) => {
  const onlyStudentsAllowed = get(restrictions, "onlyStudentsAllowed", false);

  const { formatCurrency } = useCurrencies();

  const formattedTenantServiceFee = formatCurrency({
    amount: sftValue || 0,
    currency,
    lang,
  });

  return (
    <React.Fragment>
      <InformationTable
        rows={[
          {
            key: t("monthlyRent"),
            value: {
              text: formatCurrency({
                amount: price,
                currency,
                lang,
                fixed: true,
              }),
            },
          },
          {
            key: t("deposit"),
            value: {
              text:
                deposit || deposit === 0
                  ? formatCurrency({
                      amount: deposit,
                      currency,
                      lang,
                      fixed: true,
                    })
                  : null,
            },
          },
          {
            key: t("cleaningFee"),
            value: {
              text:
                finalCleaningFee || finalCleaningFee === 0
                  ? formatCurrency({
                      amount: finalCleaningFee,
                      currency,
                      lang,
                      fixed: true,
                    })
                  : null,
            },
          },
          {
            key: trackable(
              <TenantServiceFeeInfoPopOver
                placement={placements.top}
                title={
                  <h4
                    className="ListingCostSummary-key"
                    data-testid="ListingCostSummary-TenantServiceFee"
                  >
                    <span>{t("wunderflatsTenantServiceFee")}</span>
                    <InfoIcon fillColor="#575d64" />
                  </h4>
                }
              />,
              () => {
                if (tenantServiceFeeViewedCallback) {
                  tenantServiceFeeViewedCallback();
                }
              },
            ),
            value: {
              text: (
                <div>
                  <p
                    data-testid="CostSummaryTenantServiceFee"
                    data-value={sftValue}
                  >
                    {formattedTenantServiceFee}
                  </p>
                  <p className="ListingCostSummary-extension">
                    {t("taxInclusive")}
                  </p>
                </div>
              ),
            },
          },
        ].filter(Boolean)}
      />

      <div className="ListingCostSummary-extraInfo">
        <div className="ListingCostSummary-extraInfo-icon">
          <CoinsIcon width={22} height={22} />
        </div>
        <p
          data-testid="ListingCostSummary-extraInfo-text"
          className="ListingCostSummary-extraInfo-text"
        >
          {t("pages.bookingWizard.listingCostSummary.moreInfo")}
        </p>
      </div>
      {onlyStudentsAllowed && (
        <div className="ListingCostSummary-extraInfo">
          <div className="ListingCostSummary-extraInfo-icon">
            <StudentIcon width={22} height={22} />
          </div>
          <p
            data-testid="ListingCostSummary-extraInfo-student-text"
            className="ListingCostSummary-extraInfo-text"
          >
            <b>
              {t("pages.bookingWizard.listingCostSummary.studentInfo.title")}
            </b>
            <br />
            {t("pages.bookingWizard.listingCostSummary.studentInfo.text")}
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

ListingCostSummary.propTypes = propTypes;

export default ListingCostSummary;
