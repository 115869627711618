import React from "react";
import PropTypes from "prop-types";

const Eye = ({ className, width, height }) => (
  <svg
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill="#fff"
        d="M9 5.11c-4.85 0-7.5 4.22-7.5 4.22s2.65 4.21 7.5 4.21 7.5-4.21 7.5-4.21S13.85 5.11 9 5.11z"
      />
      <path
        fill="#24272e"
        d="M17.14 8.93C17 8.74 14.21 4.36 9 4.36S1 8.74.86 8.93l-.25.4.25.39C1 9.91 3.79 14.29 9 14.29s8-4.38 8.14-4.57l.25-.39zM7.55 6.07a1.41 1.41 0 11-1.4 1.41 1.41 1.41 0 011.4-1.41zM9 12.79a8.68 8.68 0 01-6.58-3.47 9.84 9.84 0 012.94-2.51 3.79 3.79 0 00-.27 1.4 3.75 3.75 0 107.12-1.61 9.74 9.74 0 013.37 2.73A8.62 8.62 0 019 12.79z"
      />
    </g>
  </svg>
);

Eye.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default Eye;
