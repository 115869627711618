import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useI18n } from "../../spages/spa/context/I18nContext";
import { prepareModifiersForClassNames } from "../../utils/bem";

const propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    submitError: PropTypes.string,
    touched: PropTypes.bool,
    submitFailed: PropTypes.bool,
    data: PropTypes.shape({
      highlight: PropTypes.bool,
    }),
  }).isRequired,
  showErrors: PropTypes.oneOf(["onSubmit", "onTouch"]),
  children: PropTypes.node.isRequired,
  errorsMap: PropTypes.instanceOf(Map),
  isDisabled: PropTypes.bool,
};

const onSubmit = (meta) =>
  meta.submitFailed &&
  (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit));
const onTouch = (meta) =>
  meta.touched &&
  (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit));

const parseApiError = (error) => {
  if (!error) return undefined;

  const errorType = error.split(":")[0];
  const expectation = error.split(":")[1];

  // If error is not in format type:expectation, return error as is
  if (!errorType || !expectation) return error;

  return { name: errorType, expectation };
};

const FinalFormErrorIndicator = (props) => {
  const { t } = useI18n();
  const {
    input,
    meta,
    showErrors: _showErrors = "onSubmit",
    errorsMap = new Map(),
    isDisabled = false,
  } = props;

  let showErrors;

  switch (_showErrors) {
    case "onTouch":
      showErrors = onTouch(meta);
      break;
    case "onSubmit":
      showErrors = onSubmit(meta);
      break;

    default:
      throw new Error("Unknown showErrors value");
  }

  let error = meta.error || parseApiError(meta.submitError);

  const errorMessage =
    (typeof error === "string" && errorsMap.get(error)) || // override error message if it is in errorsMap
    t(`errors.${error?.name || error}`, error, true) ||
    error;

  if (typeof error === "string") error = error.toLowerCase();

  return (
    <span
      data-field={input.name}
      className={classnames(
        "ErrorIndicator",
        props.className,
        !isDisabled &&
          prepareModifiersForClassNames("ErrorIndicator", {
            invalid: showErrors && error !== "required",
            required: showErrors && error === "required",
            highlight: meta.data.highlight,
          }),
      )}
    >
      {props.children}
      {showErrors && !isDisabled && (
        <span
          data-errortype={error.name || error}
          data-testid="FieldErrorMessage"
          className={classnames(
            "ErrorIndicator-message",
            prepareModifiersForClassNames("ErrorIndicator", {
              error: showErrors && error !== "required",
              required: showErrors && error === "required",
            }),
          )}
        >
          {errorMessage}
        </span>
      )}
    </span>
  );
};

FinalFormErrorIndicator.propTypes = propTypes;
export default FinalFormErrorIndicator;
