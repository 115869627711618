import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useFeatureFlags } from "../../contexts/FeatureFlagContext";
import { isEmail } from "../../utils/validation";
import InputWithButton from "../FormElements/InputWithButton/InputWithButton";
import toaster from "../Toaster/Toaster";
import {
  RECAPTCHA_BROWSER_COMPATIBILITY_LINK,
  STEP_EMAIL,
  STEP_EMAIL_VERIFICATION,
  STEP_LOGIN_CODE,
  STEP_PASSWORD,
  STEP_SIGNUP,
} from "./constants";
import getReCaptchaToken from "./getReCaptchaToken";

const EmailForm = ({
  analyticsEvent,
  autoFocus = true,
  changeStep,
  email,
  emailExists,
  hiddenPasswordInputRef,
  isEditable = true,
  login,
  onLogin,
  setEmail,
  setIsEditable,
  t,
  snowplowEvent,
}) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const inputRef = useRef();

  // WU-1814 Clenup login code (renamed to DEV-4428)
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const showLoginWithCode = isFeatureFlagEnabled("ENVIRONMENT_LOGIN_CODE");

  React.useEffect(() => {
    // Browsers (Such as Firefox) often keep form data on soft reload.
    // That's why we're checking the default value of the input.
    const defaultValue = inputRef.current.value();
    if (defaultValue) {
      setEmail(defaultValue);
    }

    analyticsEvent("Email step view");
    // This useEffect should only be run on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEdit = () => {
    setIsEditable(true);

    // User wants to change his/her email address.
    // So, we're setting current step as STEP_EMAIL.
    changeStep(STEP_EMAIL);
    setEmail("");

    analyticsEvent("Password step cancel");
  };

  const onSubmit = async () => {
    if (!isEmail(email)) {
      analyticsEvent("Email step error");
      return setIsInvalid(true);
    }

    setIsInvalid(false);

    // Users might use auto-fill feature.
    // That's why we're checking "hidden password input" first.
    try {
      const hiddenPasswordValue =
        hiddenPasswordInputRef.current && hiddenPasswordInputRef.current.value;
      if (hiddenPasswordValue) {
        const loginReCaptchaToken = await getReCaptchaToken({
          action: "login",
        });

        const user = await login({
          email,
          password: hiddenPasswordValue,
          reCaptchaToken: loginReCaptchaToken,
        });

        analyticsEvent("loginComplete");

        if (onLogin) {
          onLogin(user);
        }

        return;
      }
    } catch (error) {
      if (error.name === "AuthenticationRequiredError") {
        changeStep(STEP_EMAIL_VERIFICATION);
        return;
      }
    }

    try {
      const emailExistsReCaptchaToken = await getReCaptchaToken({
        action: "emailExists",
      });

      const exists = await emailExists({
        email: email.trim().toLowerCase(),
        reCaptchaToken: emailExistsReCaptchaToken,
      });

      const nextStep = exists ? STEP_PASSWORD : STEP_SIGNUP;

      setIsEditable(false);
      changeStep(nextStep);
      analyticsEvent("Email step submitted");
      snowplowEvent({ loginMethod: "email" });
    } catch (error) {
      analyticsEvent("Email step error");

      if (error && error.name === "InvalidEmail") {
        return setIsInvalid(true);
      }
      const isReCaptchaValidationError =
        error.name === "ReCaptchaValidationError";

      let toasterTitle = t("components.ErrorMessage.ServerError");
      let toasterText = "";
      if (isReCaptchaValidationError) {
        toasterTitle = t("reCaptchaValidationErrorTitle");
        toasterText = showLoginWithCode
          ? t("reCaptchaValidationErrorLoginCodeText")
          : t("reCaptchaValidationErrorText");
      }

      toaster.basicToaster.add({
        dataTestId: "reCaptchaErrorToast",
        intent: toaster.intents.danger,
        text: toasterText,
        timeout: 5000,
        title: toasterTitle,
        buttonLink:
          isReCaptchaValidationError &&
          !showLoginWithCode &&
          RECAPTCHA_BROWSER_COMPATIBILITY_LINK,
        buttonOnClick:
          isReCaptchaValidationError &&
          showLoginWithCode &&
          (() => {
            setIsEditable(false);
            changeStep(STEP_LOGIN_CODE);
          }),
        buttonLabel:
          isReCaptchaValidationError &&
          (!showLoginWithCode
            ? t("reCaptchaValidationErrorTextLink")
            : t("components.AuthComponent.PasswordForm.useLoginCode")),
      });

      throw error;
    }
  };

  const onChange = (event) => {
    if (isInvalid === true) {
      setIsInvalid(false);
    }

    const email = event.target.value;
    setEmail(email);
  };

  return (
    <div data-testid="email-form" className="AuthComponent-Form EmailForm">
      <InputWithButton
        type="email"
        name="email"
        icon="trash"
        autoComplete="email"
        placeholder={t("components.AuthComponent.EmailForm.placeholder")}
        btnLabel={t("components.AuthComponent.EmailForm.cta")}
        error={isInvalid ? t("errors.must-be-email") : null}
        value={email}
        onChange={onChange}
        onSubmit={onSubmit}
        onEdit={onEdit}
        editable={isEditable}
        ref={inputRef}
        autoFocus={autoFocus}
      />
    </div>
  );
};

EmailForm.propTypes = {
  analyticsEvent: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  changeStep: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  emailExists: PropTypes.func.isRequired,
  hiddenPassword: PropTypes.string,
  hiddenPasswordInputRef: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  onLogin: PropTypes.func,
  setEmail: PropTypes.func.isRequired,
  setIsEditable: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  snowplowEvent: PropTypes.func.isRequired,
};

export default EmailForm;
