import React from "react";
import PropTypes from "prop-types";
import {
  STEP_EMAIL,
  STEP_EMAIL_VERIFICATION,
  STEP_LOGIN_CODE,
  STEP_PASSWORD,
  STEP_SIGNUP,
} from "../../../../components/AuthComponent/constants";

const StepEmailHeader = ({ t }) => (
  <header className="AuthPage-header">
    <h2 className="AuthPage-header-title">
      {t("spages.authPage.emailStep.title")}
    </h2>
    <p className="AuthPage-header-description">
      {t("spages.authPage.emailStep.description")}
    </p>
  </header>
);

StepEmailHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

const StepPasswordHeader = ({ t }) => (
  <header className="AuthPage-header">
    <h2 className="AuthPage-header-title">
      {t("spages.authPage.passwordStep.title")}
    </h2>
    <p className="AuthPage-header-description">
      {t("spages.authPage.passwordStep.description")}
    </p>
  </header>
);

StepPasswordHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

const StepLoginCodeHeader = ({ t }) => (
  <header className="AuthPage-header">
    <h2 className="AuthPage-header-title">
      {t("spages.authPage.loginCodeStep.title")}
    </h2>
    <p className="AuthPage-header-description">
      {t("spages.authPage.loginCodeStep.description")}
    </p>
  </header>
);

StepLoginCodeHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

const StepSignupHeader = ({ t }) => (
  <header className="AuthPage-header StepSignupHeader">
    <h2 className="AuthPage-header-title">
      {t("spages.authPage.signupStep.title")}
    </h2>
    <p className="AuthPage-header-description">
      {t("spages.authPage.signupStep.description")}
    </p>
  </header>
);

StepSignupHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

const HEADERS = {
  [STEP_EMAIL]: StepEmailHeader,
  [STEP_PASSWORD]: StepPasswordHeader,
  [STEP_LOGIN_CODE]: StepLoginCodeHeader,
  [STEP_SIGNUP]: StepSignupHeader,
  [STEP_EMAIL_VERIFICATION]: null,
};

const AuthPageHeader = ({ step, t }) => {
  const CurrentHeader = HEADERS[step];

  if (CurrentHeader) {
    return <CurrentHeader t={t} />;
  }

  return null;
};

AuthPageHeader.propTypes = {
  step: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default AuthPageHeader;
