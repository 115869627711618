import React from "react";
import {
  arrayOf,
  node,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from "prop-types";
import getCoverImageData from "../../utils/cover-image";
import CoverImage from "../CoverImage/CoverImage";

const propTypes = {
  className: string,
  coverImage: oneOfType([string, object]),
  pictures: arrayOf(
    shape({
      imageId: string.isRequired,
      urls: shape({
        large: string.isRequired,
        medium: string.isRequired,
        og: string.isRequired,
        original: string.isRequired,
        small: string.isRequired,
        thumbnail: string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  children: node,
  fallbackSize: oneOf([
    "large",
    "medium",
    "og",
    "original",
    "small",
    "thumbnail",
  ]),
  sizes: string,
};

const ListingCoverImage = ({
  coverImage,
  pictures,
  fallbackSize = "medium",
  className,
  sizes,
  children,
}) => {
  const { imageUrls, imageSrc } = getCoverImageData(
    { coverImage, pictures },
    fallbackSize,
  );

  return (
    <CoverImage
      className={`ListingCoverImage${className ? ` ${className}` : ""}`}
      srcSet={
        imageUrls
          ? `
        ${imageUrls.large} 1260w,
        ${imageUrls.og} 1200w,
        ${imageUrls.medium} 1024w,
        ${imageUrls.small} 640w,
        ${imageUrls.thumbnail} 640w`
          : undefined
      }
      src={imageSrc}
      sizes={sizes}
    >
      {children}
    </CoverImage>
  );
};

ListingCoverImage.propTypes = propTypes;

export default ListingCoverImage;
