import React, {
  forwardRef,
  Fragment,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { useTracker } from "../../spages/spa/context/TrackerContext";
import getWFCONFIG from "../../utils/WF_CONFIG";
import {
  STEP_EMAIL,
  STEP_EMAIL_VERIFICATION,
  STEP_LOGIN_CODE,
  STEP_PASSWORD,
  STEP_SIGNUP,
} from "./constants";
import EmailForm from "./EmailForm";
import EmailVerification from "./EmailVerification";
import HiddenPasswordInput from "./HiddenPasswordInput";
import LoginCode from "./LoginCode/LoginCode";
import PasswordForm from "./PasswordForm";
import SignupForm from "./SignupForm";
import SocialButtons from "./SocialButtons";

const WF_CONFIG = getWFCONFIG();

const ENV = WF_CONFIG.NODE_ENV;
const IS_RE_CAPTCHA_ENABLED = ENV === "production" || ENV === "staging";
const RE_CAPTCHA_SITE_KEY = WF_CONFIG.RE_CAPTCHA_SITE_KEY;

const AuthComponent = forwardRef(
  (
    {
      customerType,
      analytics,
      className,
      emailExists,
      eventLabel = null,
      isAPhone,
      login,
      onLogin,
      onStep,
      redirect,
      sendPasswordResetLink,
      shortlist,
      signup,
      t,
      url,
      autoFocusEmail = true,
      lang = "en",
    },
    ref,
  ) => {
    const [step, setStep] = useState(STEP_EMAIL);

    const [email, setEmail] = useState("");
    const [isEmailEditable, setIsEmailEditable] = useState(true);
    const [emailFormKey, setEmailFormKey] = useState(1);
    const hiddenPasswordInputRef = React.useRef();
    const { tracker } = useTracker();

    const analyticsEvent = useCallback(
      (action) => {
        if (!eventLabel || !analytics) {
          return;
        }

        analytics.event("LoginSignup", action, eventLabel);
      },
      [analytics, eventLabel],
    );

    const changeStep = (step) => {
      if (step === STEP_EMAIL) {
        setIsEmailEditable(true);
      }

      setStep(step);

      if (onStep) {
        onStep(step);
      }
    };

    const changeStepToPassword = (email) => {
      setEmail(email);
      changeStep(STEP_PASSWORD);
      setIsEmailEditable(false);
    };

    useImperativeHandle(ref, () => ({
      changeStepToPassword,
      changeStep: (desiredStep) => {
        changeStep(desiredStep);

        // We need to re-render EmailForm in this case and
        // updating its key is the easiest way.
        if (desiredStep === STEP_EMAIL) {
          setEmailFormKey(emailFormKey + 1);
        }
      },
    }));

    const onLoginCallback = async (user) => {
      await shortlist.onLogin(user);
      if (onLogin) {
        onLogin(user);
      }
    };

    const onEmailFormSubmit = (event) => {
      event.preventDefault();
    };

    if (step === STEP_EMAIL_VERIFICATION) {
      return (
        <EmailVerification
          changeStep={changeStep}
          email={email}
          setEmail={setEmail}
          t={t}
        />
      );
    }

    return (
      <Fragment>
        {/* Initialize reCaptcha V3 */}
        {IS_RE_CAPTCHA_ENABLED && (
          <Helmet>
            <script
              src={`https://www.google.com/recaptcha/api.js?render=${RE_CAPTCHA_SITE_KEY}`}
            />
          </Helmet>
        )}

        <div className={classNames("AuthComponent", className)}>
          {/* Wrap "email" and "hidden password" inputs with "form" element to enable auto-fill */}
          <form onSubmit={onEmailFormSubmit}>
            {(step === STEP_EMAIL ||
              step === STEP_PASSWORD ||
              step === STEP_LOGIN_CODE) && (
              <EmailForm
                analyticsEvent={analyticsEvent}
                changeStep={changeStep}
                email={email}
                emailExists={emailExists}
                hiddenPasswordInputRef={hiddenPasswordInputRef}
                key={emailFormKey}
                login={login}
                onLogin={onLoginCallback}
                setEmail={setEmail}
                isEditable={isEmailEditable}
                setIsEditable={setIsEmailEditable}
                t={t}
                autoFocus={autoFocusEmail}
                snowplowEvent={tracker.events.trackSignupClicked}
              />
            )}

            {step === STEP_EMAIL && (
              <HiddenPasswordInput ref={hiddenPasswordInputRef} />
            )}
          </form>

          {step === STEP_EMAIL && (
            <p
              className="ReCaptchaTOS"
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: t("components.AuthComponent.EmailForm.reCaptchaTOS", {
                  privacyPolicyLink: `<a href="https://policies.google.com/privacy" target="_blank" class="ReCaptchaTOS-link">${t(
                    "components.AuthComponent.SignupForm.privacyPolicy",
                  )}</a>`,
                  termsOfServiceLink: `<a href="https://policies.google.com/terms" target="_blank" class="ReCaptchaTOS-link">${t(
                    "components.TosAcceptModal.title",
                  )}</a>`,
                }),
              }}
            />
          )}

          {step === STEP_PASSWORD && (
            <PasswordForm
              analyticsEvent={analyticsEvent}
              changeStep={changeStep}
              email={email}
              isAPhone={isAPhone}
              login={login}
              onLogin={onLoginCallback}
              sendPasswordResetLink={sendPasswordResetLink}
              t={t}
              snowplowEvent={tracker.events.trackSignupClicked}
            />
          )}

          {step === STEP_LOGIN_CODE && (
            <LoginCode
              analyticsEvent={analyticsEvent}
              changeStep={changeStep}
              email={email}
              onLogin={onLoginCallback}
            />
          )}

          {step === STEP_EMAIL && (
            <SocialButtons
              eventLabel={eventLabel}
              redirect={encodeURIComponent(redirect)}
              t={t}
            />
          )}

          {step === STEP_SIGNUP && (
            <SignupForm
              analyticsEvent={analyticsEvent}
              changeStep={changeStep}
              customerType={customerType}
              email={email}
              getGAClientId={analytics.getClientIdPromise}
              getGASessionId={analytics.getSessionIdPromise}
              redirect={redirect}
              signup={signup}
              t={t}
              url={url}
              lang={lang}
              isAPhone={isAPhone}
            />
          )}
        </div>
      </Fragment>
    );
  },
);

AuthComponent.propTypes = {
  customerType: PropTypes.oneOf(["landlord", "tenant"]).isRequired,
  analytics: PropTypes.object,
  className: PropTypes.string,
  emailExists: PropTypes.func.isRequired,
  eventLabel: PropTypes.string,
  isAPhone: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  onLogin: PropTypes.func,
  onStep: PropTypes.func,
  redirect: PropTypes.string.isRequired,
  sendPasswordResetLink: PropTypes.func.isRequired,
  shortlist: PropTypes.object.isRequired,
  signup: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  autoFocusEmail: PropTypes.bool,
  lang: PropTypes.oneOf(["en", "de", "fr", "uk", "ru"]),
};

export default AuthComponent;
