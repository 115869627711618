import React from "react";
import PropTypes from "prop-types";
import { useFeatureFlags } from "../../contexts/FeatureFlagContext";
import ShowPasswordToggle from "../AuthComponent/ShowPasswordToggle";
import InputPassword from "../FormElements/InputPassword/InputPassword";
import InputText from "../FormElements/InputText/InputText";
import InputWrapper from "../FormElements/InputWrapper/InputWrapper";

const ResetPasswordForm = React.forwardRef(
  (
    {
      t,
      label,
      showPassword,
      toggleShowPassword,
      onSubmit,
      errorMessage,
      value,
      setValue,
    },
    ref,
  ) => {
    // TODO DEV-14774 Remove feature flag usage
    const { isFeatureFlagEnabled } = useFeatureFlags();
    const isWeakPasswordFeatureFlagEnabled = isFeatureFlagEnabled(
      "ENVIRONMENT_WEAK_PASSWORDS",
    );

    return (
      <form method="post" onSubmit={onSubmit} ref={ref}>
        <InputWrapper
          className="ResetPasswordForm-inputWrapper"
          label={label}
          error={isWeakPasswordFeatureFlagEnabled ? null : errorMessage}
          labelOnTop
        >
          {isWeakPasswordFeatureFlagEnabled && (
            <InputPassword
              name="password"
              className="ResetPasswordForm-inputText"
              placeholder={t(
                "components.AuthComponent.SignupForm.inputs.passwordPlaceholder",
              )}
              error={errorMessage}
              showPassword={showPassword}
              toggleShowPassword={toggleShowPassword}
              value={value}
              onChange={setValue}
              t={t}
            />
          )}
          {!isWeakPasswordFeatureFlagEnabled && (
            <InputText
              name="password"
              className="ResetPasswordForm-inputText"
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              placeholder={t(
                "components.AuthComponent.SignupForm.inputs.passwordPlaceholder",
              )}
              error={errorMessage}
            >
              <ShowPasswordToggle
                className="ResetPasswordForm-showPasswordToggle"
                onClick={toggleShowPassword}
                showPassword={showPassword}
                onlyIcon
              />
            </InputText>
          )}
        </InputWrapper>
      </form>
    );
  },
);

ResetPasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  label: PropTypes.string,
  showPassword: PropTypes.bool.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
};

export default ResetPasswordForm;
