import React, { forwardRef } from "react";

// In order to provide auto-fill feature to users,
// we're rendering a hidden password field.
const HiddenPasswordInput = forwardRef((props, ref) => {
  return (
    <div className="HiddenPasswordInput">
      <input name="password" ref={ref} tabIndex="-1" type="password" />
    </div>
  );
});

export default HiddenPasswordInput;
