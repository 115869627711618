import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.any,
  subLabel: PropTypes.any,
  error: PropTypes.any,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  labelOnTop: PropTypes.bool,
  showAsterisk: PropTypes.bool,
  WrapperComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onFocus: PropTypes.func,
};

const InputWrapper = ({
  children,
  label,
  subLabel,
  labelOnTop,
  showAsterisk,
  error,
  className = "",
  dataTestId,
  WrapperComponent = "label",
  onFocus,
}) => {
  const wrapperComponentRef = React.useRef();

  return (
    <WrapperComponent
      className={classnames(
        "InputWrapper",
        {
          "InputWrapper--labelOnTop": labelOnTop,
        },
        className,
      )}
      data-testid={dataTestId}
      ref={wrapperComponentRef}
    >
      <label className="InputWrapper-label-container">
        <span className="InputWrapper-label">
          {label}
          {showAsterisk && <span className="asterisk">*</span>}
        </span>
        {subLabel && <span className="InputWrapper-label-sub">{subLabel}</span>}
      </label>
      <div
        onFocus={() => {
          if (onFocus) {
            onFocus(wrapperComponentRef);
          }
        }}
        className="InputWrapper-children"
      >
        {children}
      </div>
      <span className="InputWrapper-error" data-testid="InputWrapper-error">
        {error}
      </span>
    </WrapperComponent>
  );
};

InputWrapper.propTypes = propTypes;

export default InputWrapper;
