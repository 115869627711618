import React from "react";
import PropTypes from "prop-types";

const EyeClosed = ({ className, width, height }) => (
  <svg
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill="#fff"
        d="M9 5.11c-4.85 0-7.5 4.22-7.5 4.22s2.65 4.21 7.5 4.21 7.5-4.21 7.5-4.21S13.85 5.11 9 5.11z"
      />
      <path
        d="M17.14 8.93a11.28 11.28 0 00-3-3l2.47-2.45-1.08-1.06-2.78 2.76-6.1 6.06-.83.83-1.13 1.12-2.29 2.28 1.06 1.06 2.72-2.7a8.59 8.59 0 002.82.46c5.21 0 8-4.38 8.14-4.57l.25-.39zM9 12.79a7.54 7.54 0 01-1.6-.18l.74-.72a4 4 0 00.7.07 3.71 3.71 0 003.68-4.43L13 7a9.91 9.91 0 012.56 2.3A8.62 8.62 0 019 12.79zm-5.6-.43l1.09-1.08a10.23 10.23 0 01-2.07-2 9.84 9.84 0 012.94-2.47 3.79 3.79 0 00-.27 1.4 3.65 3.65 0 00.55 1.93L7 8.78a1.41 1.41 0 111.85-1.84l2.32-2.31A8.77 8.77 0 009 4.36c-5.21 0-8 4.38-8.14 4.57l-.25.4.25.39a11.14 11.14 0 002.54 2.64z"
        fill="e9ebf2"
      />
    </g>
  </svg>
);

EyeClosed.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default EyeClosed;
