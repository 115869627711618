import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const cardHeaderPropTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  titleSize: PropTypes.oneOf(["default", "small"]),
  sentiment: PropTypes.oneOf(["default", "danger", "gradient"]),
  icon: PropTypes.node,
};
export const CardHeader = ({
  title,
  subtitle,
  titleSize = "default",
  sentiment = "default",
  icon,
}) => {
  return (
    <div className="CardModal-cardHeaderContainer">
      {icon && (
        <div
          className={classnames("CardModal-headerIconContainer", {
            "CardModal-headerIconContainer--danger": sentiment === "danger",
            "CardModal-headerIconContainer--gradient": sentiment === "gradient",
          })}
        >
          {icon}
        </div>
      )}
      <h2
        className={classnames("CardModal-headerTitle", {
          "CardModal-headerTitle--small": titleSize === "small",
        })}
        size={titleSize}
      >
        {title}
      </h2>
      <h2 className="CardModal-headerSubtitle">{subtitle}</h2>
    </div>
  );
};
CardHeader.propTypes = cardHeaderPropTypes;

const cardBodyPropTypes = {
  children: PropTypes.node,
};
export const CardBody = ({ children }) => (
  <div className="CardModal-cardBodyContainer">{children}</div>
);
CardBody.propTypes = cardBodyPropTypes;

const cardFooterPropTypes = {
  children: PropTypes.node,
};
export const CardFooter = ({ children }) => {
  return <div className="CardModal-cardFooterContainer">{children}</div>;
};
CardFooter.propTypes = cardFooterPropTypes;

const cardButtonGroupPropTypes = {
  children: PropTypes.node,
};
export const CardButtonGroup = ({ children }) => (
  <div className="CardModal-buttonsContainer">{children}</div>
);
CardButtonGroup.propTypes = cardButtonGroupPropTypes;
