import * as React from "react";

const PasswordLock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 66 66"
    style={{ backgroundColor: "white" }}
  >
    <rect
      width="63"
      height="63"
      x="1.5"
      y="1.5"
      stroke="#EDF1F4"
      strokeWidth="3"
      rx="31.5"
    />
    <circle cx="33" cy="33" r="27" fill="#EDF1F4" />
    <path
      fill="#fff"
      d="M32.38 44.267a7.367 7.367 0 1 0 0-14.734 7.367 7.367 0 0 0 0 14.733Z"
    />
    <path
      fill="#000"
      d="M37.146 29.938V25.33a4.704 4.704 0 0 0-4.694-4.694h-.145a4.694 4.694 0 0 0-4.694 4.694v4.594A8.42 8.42 0 0 0 23.93 36.9c0 4.651 3.784 8.45 8.45 8.45s8.45-3.799 8.45-8.45a8.42 8.42 0 0 0-3.684-6.962M29.78 25.33a2.53 2.53 0 0 1 2.527-2.528h.145a2.54 2.54 0 0 1 2.528 2.528v3.54a8.2 8.2 0 0 0-2.6-.42 8.5 8.5 0 0 0-2.6.405zm2.6 17.854a6.29 6.29 0 0 1-6.284-6.284c0-1.66.65-3.163 1.69-4.275a6.1 6.1 0 0 1 1.994-1.43 6.1 6.1 0 0 1 2.6-.578 6.1 6.1 0 0 1 2.6.578 5.75 5.75 0 0 1 1.979 1.444 6.12 6.12 0 0 1 1.704 4.261c0 3.467-2.831 6.284-6.283 6.284"
    />
    <path
      fill="#000"
      d="M34.632 35.643a2.256 2.256 0 0 0-2.254-2.253 2.256 2.256 0 0 0-2.253 2.253c0 .867.491 1.603 1.199 1.979l-.419 2.788h2.947l-.448-2.76a2.26 2.26 0 0 0 1.228-1.993z"
    />
  </svg>
);

export default PasswordLock;
