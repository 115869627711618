import React from "react";
import PropTypes from "prop-types";
import CoverImage from "../../../../components/CoverImage/CoverImage";
import ListingSummary from "../../../../components/ListingSummary/ListingSummary";
import {
  getSidebarImageSrcSet,
  getSidebarImageUrl,
} from "../../../../utils/GCPArtefacts/static-artefacts";
import { getListingSummaryCoverImageProps } from "../BookingWizardPage/utils";

const propTypes = {
  t: PropTypes.func.isRequired,
  customerType: PropTypes.oneOf(["tenant", "landlord"]),
  lang: PropTypes.string,
  listing: PropTypes.object,
};

const AuthPageSidebar = ({
  t,
  customerType = "tenant",
  listing,
  lang = "en",
}) => {
  if (listing && listing.deleted === false) {
    return (
      <ListingSummary
        {...listing}
        coverImageProps={getListingSummaryCoverImageProps(listing, lang)}
        lang={lang}
        listingId={listing.id}
      />
    );
  }

  return (
    <div className="AuthPageSidebar">
      <CoverImage
        className="AuthPageSidebar-image"
        src={getSidebarImageUrl(customerType, 840)}
        srcSet={getSidebarImageSrcSet(customerType)}
        sizes="420px, (max-width: 480px) 0px"
      />
      <div className="AuthPageSidebar-promotionOuter">
        <article className="AuthPageSidebar-promotionItem">
          <h2 className="AuthPageSidebar-promotionTitle">
            {t(`spages.authPage.promotionItem1.${customerType}.title`)}
          </h2>
          <p className="AuthPageSidebar-promotionDescription">
            {t(`spages.authPage.promotionItem1.${customerType}.description`)}
          </p>
        </article>

        <article className="AuthPageSidebar-promotionItem">
          <h2 className="AuthPageSidebar-promotionTitle">
            {t(`spages.authPage.promotionItem2.${customerType}.title`)}
          </h2>
          <p className="AuthPageSidebar-promotionDescription">
            {t(`spages.authPage.promotionItem2.${customerType}.description`)}
          </p>
        </article>

        <article className="AuthPageSidebar-promotionItem">
          <h2 className="AuthPageSidebar-promotionTitle">
            {t(`spages.authPage.promotionItem3.${customerType}.title`)}
          </h2>
          <p className="AuthPageSidebar-promotionDescription">
            {t(`spages.authPage.promotionItem3.${customerType}.description`)}
          </p>
        </article>
      </div>
    </div>
  );
};

AuthPageSidebar.propTypes = propTypes;

export default AuthPageSidebar;
